import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ReactWOW from "react-wow";

import "./../css/company.scss";

import { restApiHost} from "./../layout/apihost";

import "react-multi-carousel/lib/styles.css";
import Techstacks from "./../component/techstacks";

import company11x from "./../images/company/company-1-1x.png";
import company12x from "./../images/company/company-1-2x.png";
import company21x from "./../images/company/company-2-1x.png";
import company22x from "./../images/company/company-2-2x.png";
import company31x from "./../images/company/company-3-1x.png";
import company32x from "./../images/company/company-3-2x.png";
import company41x from "./../images/company/company-4-1x.png";
import company42x from "./../images/company/company-4-2x.png";
import company51x from "./../images/company/company-5-1x.png";
import company52x from "./../images/company/company-5-2x.png";
import company61x from "./../images/company/company-6-1x.png";
import company62x from "./../images/company/company-6-2x.png";
import company71x from "./../images/company/company-7-1x.png";
import company72x from "./../images/company/company-7-2x.png";
import company81x from "./../images/company/company-8-1x.png";
import company82x from "./../images/company/company-8-2x.png";
import company91x from "./../images/company/company-9-1x.png";
import company92x from "./../images/company/company-9-2x.png";
import company101x from "./../images/company/company-10-1x.png";
import company102x from "./../images/company/company-10-2x.png";
import company111x from "./../images/company/company-11-1x.png";
import company112x from "./../images/company/company-11-2x.png";

import imgLogoLinkedin from "./../img/imgLogoLinkedin.svg";

import bgWantToLearnMore from "./../img/bgWantToLearnMore.svg";
import axios from "axios";


function CompanyPage(props) {
  const ref = useRef(null);
  // const ref2 = useRef(null);
  // const ref3 = useRef(null);
  const [company1WD, setCompany1WD] = useState('');
  const [company2WD, setCompany2WD] = useState('');
  const [company3WD, setCompany3WD] = useState('');
  const [company4WD, setCompany4WD] = useState('');
  const [company5WD, setCompany5WD] = useState('');
  const [company11WD, setCompany11WD] = useState('');
  const [company6WD, setCompany6WD] = useState('');
  const [company7WD, setCompany7WD] = useState('');
  const [company8WD, setCompany8WD] = useState('');
  const [company9WD, setCompany9WD] = useState('');
  const [company10WD, setCompany10WD] = useState('');

  const [aboutUs, setAboutUs] = useState(false);
  const [whoWeAre, setWhoWeAre] = useState(false);
  const [whoWeAreImage, setWhoWeAreImage] = useState(false);
  const [lider, setLider] = useState(false);
  const [workFlexibly, setWorkFlexibly] = useState(false);
  const [levelUpCareer, setLevelUpCareer] = useState(false);
  const [learnTeach, setLearnTeach] = useState(false);
  const [loveFun, setLoveFun] = useState(false);
  const [talent, setTalent] = useState(false);
  const getPageInfo = async () => {
    await axios
        .get("/page/company")
        .then(({ data }) => {
          const page = data;
          // 404
          if (!page) {
            props.history.push("/404");
          } else {
            setAboutUs(page.about_us);
            setWhoWeAre(page.who_we_are);
            setWhoWeAreImage(page.image_who_we_are);
            setLider(page.lider);
            setWorkFlexibly(page.work_flexibly);
            setLevelUpCareer(page.level_up_career);
            setLearnTeach(page.learn_teach);
            setLoveFun(page.love_fun);
            setTalent(page.talent);
          }
        });
  }


  useEffect(() => {
    getPageInfo();
    if( props.wd < 1200 ){
      setCompany1WD(company11x);
      setCompany2WD(company21x);
      setCompany3WD(company31x);
      setCompany4WD(company41x);
      setCompany5WD(company51x);
      setCompany11WD(company111x);
      setCompany6WD(company61x);
      setCompany7WD(company71x);
      setCompany8WD(company81x);
      setCompany9WD(company91x);
      setCompany10WD(company101x);
    } else if( props.wd >= 1200 ){
      setCompany1WD(company12x);
      setCompany2WD(company22x);
      setCompany3WD(company32x);
      setCompany4WD(company42x);
      setCompany5WD(company52x);
      setCompany11WD(company112x);
      setCompany6WD(company62x);
      setCompany7WD(company72x);
      setCompany8WD(company82x);
      setCompany9WD(company92x);
      setCompany10WD(company102x);
    }
  },[props.wd]);


  return (

      <div>
        <Container>
          <div className="wrapBG">
            <Row className="wrapRowWeCanCreateSolutions">
              <Col xl="5" lg="5" md="12" sm="12">
                <ReactWOW delay="1s" animation="slideInUp">
                  <div className="bigText animate__animated animate__delay-1s animate__fadeInLeft">
                    <span className="colorAzure">About</span> Us
                  </div>
                </ReactWOW>
                <p className="ptopCompany pTopCompamy">
                  {aboutUs?aboutUs:null}
                </p>
              </Col>
              <Col
                  xl="7"
                  lg="7"
                  md="12"
                  sm="12"
                  className="wrapWeCanCreateSolutions"
              >
                <img
                    src={company1WD}
                    alt=" "
                    title=" "
                    className="weCanCreateSolutions"
                />
              </Col>
            </Row>
            <Row className="wrapRowImgWhoWeAre">
              <Col xl="6" lg="6" className="wrapImgWhoWeAre">
                <img
                    src={restApiHost+''+whoWeAreImage}
                    alt=" "
                    title=" "
                    className="imgWhoWeAre d-xl-block d-lg-block d-none"
                />
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <ReactWOW delay="1s" animation="slideInUp">
                  <h2 className="h2Company animate__animated animate__delay-1s animate__slideInUp">
                    Who we are
                  </h2>
                </ReactWOW>
                <ReactWOW delay="1s" animation="slideInUp">
                  <p className="pTabCompany animate__animated animate__delay-1s animate__slideInUp">
                    {whoWeAre?whoWeAre:null}
                  </p>
                </ReactWOW>
              </Col>
              <Col md="12" sm="12" className="wrapImgWhoWeAre">
                <img
                    src={company2WD}
                    alt=" "
                    title=" "
                    className="imgWhoWeAre imgWhoWeAre2 d-sm-block d-md-block d-none"
                />
              </Col>
            </Row>
            <Row className="wrapRowOurLeadershipText">
              <Col xl="12" lg="12" md="12" sm="12">
                <ReactWOW delay="1s" animation="slideInUp">
                  <h2 className="h2Main animate__animated animate__delay-1s animate__slideInUp">
                    Our Leadership
                  </h2>
                </ReactWOW>
              </Col>
            </Row>


            <Row className="wrapRowOurLeadershipBlock">

              {
                lider ?
                    lider.map(((user)=>{
                      return (
                          <Col xl="4" lg="4" className="wrapOurLeadership" ref={ref}>
                            <div className="wrapImgBlock">
                              <div className="itemImgBlock">
                                <div className="imgBlockMainFoto">
                                  <ReactWOW delay="1s" animation="slideInUp">
                                    <img
                                        src={restApiHost+''+user.image}
                                        alt=" "
                                        title=" "
                                        className="imgOurLeadershipMainFoto"
                                        style={{left: `${((ref.current ? ref.current.offsetWidth : 0)-212)/2}px`}}
                                    />
                                  </ReactWOW>
                                  <ReactWOW delay="1s" animation="slideInUp">
                                    <a href={user.linkedin} target="_blank" rel="noopener noreferrer" className="aimgLogoLinkedinCompany">
                                      <img
                                          src={imgLogoLinkedin}
                                          alt=" "
                                          title=" "
                                          className="imgLogoLinkedin"
                                      />
                                    </a>
                                  </ReactWOW>
                                </div>
                                <h3 className="h3OurLeadership">{user.name}</h3>
                                <p className="pOurLeadership">{user.job_title}</p>
                              </div>
                            </div>
                          </Col>
                      )
                    }))
                    : null
              }

            </Row>

            <Row className="wrapRowTechStacksText">
              <Col xl="12" lg="12" md="12" sm="12">
                <ReactWOW delay="1s" animation="slideInUp">
                  <h2 className="titleH2 mt-5 animate__animated animate__delay-1s animate__slideInUp">
                    Tech stacks
                  </h2>
                </ReactWOW>
              </Col>
            </Row>
            <Row className="wrapRowTechStacksImg">
              <Col xl="12" lg="12" md="12" sm="12">
                <div id="marquee1" className="marquee">
                  <div id="blockSlider" className="marquee__inner">
                    <Techstacks />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="wrapRowUnleashYourPotentialText">
              <Col xl="12" lg="12" md="12" sm="12">
                <ReactWOW delay="1s" animation="slideInUp">
                  <h2 className="titleH2 mt-5 animate__animated animate__delay-1s animate__slideInUp">
                    Unleash your potential
                  </h2>
                </ReactWOW>
              </Col>
            </Row>
            <Row className="wrapRowUnleashYourPotentialBlock">
              <Col xl="6" lg="6" md="12" sm="12">
                <div className="wrapUnleashYourPotentialText">
                  <ReactWOW delay="1s" animation="slideInUp">
                    <h3 className="h3UnleashYourPotential animate__animated animate__delay-1s animate__slideInUp">
                      Work flexibly
                    </h3>
                  </ReactWOW>
                  <ReactWOW delay="1s" animation="slideInUp">
                    <p className="pUnleashYourPotential animate__animated animate__delay-1s animate__slideInUp">
                      {workFlexibly?workFlexibly:null}
                    </p>
                  </ReactWOW>
                </div>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img
                    src={company6WD}
                    alt=" "
                    title=" "
                    className="imgUnleashYourPotential"
                />
              </Col>
            </Row>
            <Row className="wrapRowLevelUpCareerBlock">
              <Col xl="6" lg="6" className="wrapImgLevelUpCareerTop">
                <img
                    src={company7WD}
                    alt=" "
                    title=" "
                    className="imgLevelUpCareer"
                />
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <div className="wrapLevelUpCareerText">
                  <ReactWOW delay="1s" animation="slideInUp">
                    <h3 className="h3UnleashYourPotential animate__animated animate__delay-1s animate__slideInUp">
                      Level Up Your Career
                    </h3>
                  </ReactWOW>
                  <ReactWOW delay="1s" animation="slideInUp">
                    <p className="pLevelUpCareer animate__animated animate__delay-1s animate__slideInUp">
                      {levelUpCareer?levelUpCareer:null}
                    </p>
                  </ReactWOW>
                </div>
              </Col>
              <Col md="12" sm="12" className="wrapImgLevelUpCareerBottom">
                <img
                    src={company7WD}
                    alt=" "
                    title=" "
                    className="imgLevelUpCareer"
                />
              </Col>
            </Row>

            <Row className="wrapRowWeLearnTeachAndMentorBlock">
              <Col xl="6" lg="6" md="12" sm="12">
                <div className="wrapWeLearnTeachAndMentorText">
                  <ReactWOW delay="1s" animation="slideInUp">
                    <h3 className="h3UnleashYourPotential animate__animated animate__delay-1s animate__slideInUp">
                      We Learn, Teach, and Mentor
                    </h3>
                  </ReactWOW>
                  <ReactWOW delay="1s" animation="slideInUp">
                    <p className="pLevelUpCareer animate__animated animate__delay-1s animate__slideInUp">
                      {learnTeach?learnTeach:null}
                    </p>
                  </ReactWOW>
                </div>
              </Col>
              <Col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  className="wrapImgWeLearnTeachAndMentor"
              >
                <img
                    src={company8WD}
                    alt=" "
                    title=" "
                    className="imgWeLearnTeachAndMentor"
                />
              </Col>
            </Row>

            <Row className="wrapRowWeLoveFunBlock">
              <Col xl="6" lg="6" className="wrapImgWeLoveFunTop">
                <img
                    src={company9WD}
                    alt=" "
                    title=" "
                    className="imgWeLoveFun"
                />
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <div className="wrapWeLoveFunText">
                  <ReactWOW delay="1s" animation="slideInUp">
                    <h3 className="h3UnleashYourPotential animate__animated animate__delay-1s animate__slideInUp">
                      We Love Fun
                    </h3>
                  </ReactWOW>
                  <ReactWOW delay="1s" animation="slideInUp">
                    <p className="pLevelUpCareer animate__animated animate__delay-1s animate__slideInUp">
                      {loveFun?loveFun:null}
                    </p>
                  </ReactWOW>
                </div>
              </Col>
              <Col md="12" sm="12" className="wrapImgWeLoveFunBottom">
                <img
                    src={company9WD}
                    alt=" "
                    title=" "
                    className="imgWeLoveFun"
                />
              </Col>
            </Row>
            <Row className="wrapRowWeAreForTalentBlock">
              <Col xl="6" lg="6" md="12" sm="12">
                <div className="wrapWeAreForTalentText">
                  <ReactWOW delay="1s" animation="slideInUp">
                    <h3 className="h3UnleashYourPotential animate__animated animate__delay-1s animate__slideInUp">
                      We Are for Talent
                    </h3>
                  </ReactWOW>
                  <ReactWOW delay="1s" animation="slideInUp">
                    <p className="pLevelUpCareer animate__animated animate__delay-1s animate__slideInUp">
                      {talent?talent:null}
                    </p>
                  </ReactWOW>
                </div>
              </Col>
              <Col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  className="wrapImgWeAreForTalent"
              >
                <img
                    src={company10WD}
                    alt=" "
                    title=" "
                    className="imgWeAreForTalent"
                />
              </Col>
            </Row>

            <Row className="wrapRowWantToLearnMoreBlock">
              <Col xl="12" lg="12" md="12" sm="12">
                <div className="wrapWantToLearnMoreText">
                  <ReactWOW delay="1s" animation="slideInUp">
                    <h3 className="h3WantToLearnMore animate__animated animate__delay-1s animate__slideInUp">
                      Want to learn more?
                    </h3>
                  </ReactWOW>
                  <ReactWOW delay="1s" animation="slideInUp">
                    <p className="pWantToLearnMore animate__animated animate__delay-1s animate__slideInUp">
                      Tell us about your business needs. We will find the perfect
                      solution.
                    </p>
                  </ReactWOW>
                </div>
                <div className="buttonWantToLearnMore">
                  <a href="/en/contact" className="btn buttonOne">Contact us</a>
                </div>
                <img
                    src={bgWantToLearnMore}
                    alt=" "
                    title=" "
                    className="bgWantToLearnMore"
                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>

  );
}

export default CompanyPage;
