import React from "react";
import axios from "axios";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import "./../css/Successfully.scss";
import imgSuccessfully from "./../img/imgSuccessfully.svg";
class FormikLead extends React.Component {

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.hendleCloseSuccessfully = this.hendleCloseSuccessfully.bind(this);
    this.onClickButton = this.onClickButton.bind(this);

    this.state = {
      showSuccessfully: false,
      inputName: "",
      inputPhone: "",
      inputEmail: "",
      inputMessage: "",
      inputNameStart: true,
      inputPhoneStart: true,
      inputEmailStart: true,
      inputNameLabelClassName: "errorEmptyValue",
      inputPhoneLabelClassName: "errorEmptyValue",
      inputEmailLabelClassName: "errorEmptyValue",
      inputMessageLabelClassName: "errorEmptyValue",
      formErrors: {
          inputName: '', 
          inputPhone: '', 
          inputEmail: '',
      },
      inputNameValid: false,
      inputPhoneValid: false,
      inputEmailValid: false,
      formValid: false
    }
  }

  onSubmit = () => {
    this.validateField('inputName', this.state.inputName);
    this.validateField('inputPhone', this.state.inputPhone);
    this.validateField('inputEmail', this.state.inputEmail);
    this.validateForm()
    if(this.state.formValid){
      axios
        .post("/contact", {
          name: this.state.inputName,
          phone: this.state.inputPhone,
          email: this.state.inputEmail,
          message: this.state.inputMessage,
        })
        .then((response) => {
          console.log(response);
        });

      this.setState((state) => ({
        showSuccessfully: true,
      }));
      setTimeout(() => {
        this.setState((state) => ({
          showSuccessfully: false,
          inputName: "",
          inputPhone: "",
          inputEmail: "",
          inputMessage: "",
        }));
      }, 3000);
    }
  };
  
  handleUserInput (e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState((state) => ({
        [name]: value
      }),
      () => { this.validateField(name, value) }
    );
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;

    let fieldInputNameStart = this.state.inputNameStart;
    let fieldInputPhoneStart = this.state.inputPhoneStart;
    let fieldInputEmailStart = this.state.inputEmailStart;

    let inputNameValid = this.state.inputNameValid;
    let inputPhoneValid = this.state.inputPhoneValid;
    let inputEmailValid = this.state.inputEmailValid;

    switch(fieldName) {
    case 'inputName':
        inputNameValid = value.length >= 1;
        if(inputNameValid){
          fieldValidationErrors.inputName = '';
          this.setState((state) => ({ inputNameLabelClassName: "errorNotEmptyValue" }) );
        } else {
          fieldValidationErrors.inputName = 'Enter name';
          if(!value.trim()){
            this.setState((state) => ({ inputNameLabelClassName: "errorRed errorEmptyValue errorRedBorder" }) );
          } else {
            this.setState((state) => ({ inputNameLabelClassName: "errorRed errorNotEmptyValue errorRedBorder" }) );
          }
        }
        fieldInputNameStart = fieldValidationErrors.inputName ? false : true;
        break;
    case 'inputPhone':
        inputPhoneValid = value.match(/^(\+?\d{0,2})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{2}\)?)?\s?-?\s?(\(?\d{2}\)?)?$/);
        if(inputPhoneValid){
          fieldValidationErrors.inputPhone = '';
          this.setState((state) => ({ inputPhoneLabelClassName: "errorNotEmptyValue" }) );
        } else {
          fieldValidationErrors.inputPhone = 'Phone number is not valid';
          if(!value.trim()){
            this.setState((state) => ({ inputPhoneLabelClassName: "errorRed errorEmptyValue errorRedBorder" }) );
          } else {
            this.setState((state) => ({ inputPhoneLabelClassName: "errorRed errorNotEmptyValue errorRedBorder" }) );
          }
        }
        fieldInputPhoneStart = fieldValidationErrors.inputPhone ? false : true;
        break;
    case 'inputEmail':
        inputEmailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if(inputEmailValid){
          fieldValidationErrors.inputEmail = '';
          this.setState((state) => ({ inputEmailLabelClassName: "errorNotEmptyValue" }) );
        } else {
          fieldValidationErrors.inputEmail = 'This email is not correct!';
          if(!value.trim()){
            this.setState((state) => ({ inputEmailLabelClassName: "errorRed errorEmptyValue errorRedBorder" }) );
          } else {
            this.setState((state) => ({ inputEmailLabelClassName: "errorRed errorNotEmptyValue errorRedBorder" }) );
          }
        }
        fieldInputEmailStart = fieldValidationErrors.inputEmail ? false : true;
        break;
    case 'inputMessage':
        fieldValidationErrors.inputMessage = true;
        if(value){
          this.setState((state) => ({ inputMessageLabelClassName: "errorNotEmptyValue" }) );
        } else {
          this.setState((state) => ({ inputMessageLabelClassName: "errorEmptyValue" }) );
        }
        break;
    default:
        break;
    }

    this.setState(
        {
            formErrors: fieldValidationErrors,
            inputNameValid: inputNameValid,
            inputPhoneValid: inputPhoneValid,
            inputEmailValid: inputEmailValid,
            inputNameStart: fieldInputNameStart,
            inputPhoneStart: fieldInputPhoneStart,
            inputEmailStart: fieldInputEmailStart,
        }, this.validateForm);
  }

  validateForm() {
      this.setState(
          {
              formValid: this.state.inputNameValid &&
                              this.state.inputPhoneValid &&
                              this.state.inputEmailValid
          }
      );
  }
  
  onClickButton (event) {
    event.preventDefault();
    this.onSubmit();
  }

  hendleCloseSuccessfully = (e) => {
    this.setState((state) => ({
      showSuccessfully: false,
    }));
  };

  render() {
    return (
      <div>
        {/* Successfully */}
        {this.state.showSuccessfully ? (
          <Container>
            <Row>
              <Col>
                <div
                  className="wrapSuccessfullyBG"
                  onClick={this.hendleCloseSuccessfully}
                >
                  <div
                    className="wrapSuccessfully"
                    onClick={this.hendleCloseSuccessfully}
                  >
                    <h2
                      className="h2Successfully"
                      onClick={this.hendleCloseSuccessfully}
                    >
                      successfully
                    </h2>
                    <p
                      className="pSuccessfully"
                      onClick={this.hendleCloseSuccessfully}
                    >
                      Your message has been sent successfully
                    </p>
                    <img
                      src={imgSuccessfully}
                      alt=""
                      className="imgSuccessfully"
                      onClick={this.hendleCloseSuccessfully}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : null}
        {/* Successfully */}

        <Form className="formDiscuss" id="formlead" onSubmit={(e) => {this.onSubmit(); e.preventDefault();}}>
          <Row className="mt-4 mb-4">
              <Col xl="6" lg="6" md="12" sm="12" className="wrapFormCol">
                  <div className="form-group form-group-input-name">
                      <input
                          className={this.state.formErrors.inputName ? "form-control errorRedBorder" : "form-control"} 
                          type="text"
                          name="inputName"
                          placeholder=""
                          value={this.state.inputName}
                          onChange={this.handleUserInput}
                      />
                      <label 
                        className={this.state.inputNameLabelClassName}
                        htmlFor="exampleInputEmail1">
                          Name*
                      </label>
                      <div className="error errorInput">{this.state.formErrors.inputName}</div>
                  </div>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" className="wrapFormCol">
                  <div className="form-group form-group-input-phone">
                      <input
                          className={this.state.formErrors.inputPhone ? "form-control errorRedBorder" : "form-control"} 
                          type="text"
                          name="inputPhone"
                          placeholder=""
                          value={this.state.inputPhone}
                          onChange={this.handleUserInput}
                      />
                      <label 
                        className={this.state.inputPhoneLabelClassName}
                        htmlFor="exampleInputEmail1">
                          Phone*
                      </label>
                      <div className="error errorInput">{this.state.formErrors.inputPhone}</div>
                  </div>
              </Col>
          </Row>
          <Row className="mt-4 mb-4">
              <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                  <div className="form-group">
                      <input
                          className={this.state.formErrors.inputEmail ? "form-control errorRedBorder" : "form-control"} 
                          type="email"
                          name="inputEmail"
                          placeholder=""
                          value={this.state.inputEmail}
                          onChange={this.handleUserInput}
                      />
                      <label 
                        className={this.state.inputEmailLabelClassName}
                        htmlFor="exampleInputEmail1">
                          Email*
                      </label>
                      <div className="error errorInput">{this.state.formErrors.inputEmail}</div>
                  </div>
              </Col>
          </Row>
          <Row className="mt-4 mb-4">
              <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                  <div className="form-group">
                      <textarea
                          className="form-control" 
                          name="inputMessage"
                          value={this.state.inputMessage}
                          onChange={this.handleUserInput}
                      >
                      </textarea>
                      <label 
                        className={this.state.inputMessageLabelClassName}
                        htmlFor="exampleInputEmail1">
                          Message
                      </label>
                  </div>
              </Col>
            </Row>
            <Button
                variant="primary"
                type="submit"
                className="btn buttonOne buttonOneForm"
                onClick={this.onClickButton}
            >
                Send
            </Button>
        </Form>
      </div>
    );
  }
}
export default FormikLead;
