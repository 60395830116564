import React, { useState, useEffect } from "react";
import axios from "axios";
import closeButton from "./../img/closeButton.svg";
import "./../css/careers.scss";
import { Form, Container, Row, Col, Tabs, Tab, Button } from "react-bootstrap";
import ReactWOW from "react-wow";

import careers11x from "./../images/careers/careers-1-1x.png";
import careers12x from "./../images/careers/careers-1-2x.png";
import careers21x from "./../images/careers/careers-2-1x.png";
import careers22x from "./../images/careers/careers-2-2x.png";
import careers31x from "./../images/careers/careers-3-1x.png";
import careers32x from "./../images/careers/careers-3-2x.png";
import careers41x from "./../images/careers/careers-4-1x.png";
import careers42x from "./../images/careers/careers-4-2x.png";
import careers51x from "./../images/careers/careers-5-1x.png";
import careers52x from "./../images/careers/careers-5-2x.png";
import careers61x from "./../images/careers/careers-6-1x.png";
import careers62x from "./../images/careers/careers-6-2x.png";
import careers71x from "./../images/careers/careers-7-1x.png";
import careers72x from "./../images/careers/careers-7-2x.png";

import imgLine1to2 from "./../img/imgLine1to2.svg";
import imgLine2to3 from "./../img/imgLine2to3.svg";
import imgLine3to4 from "./../img/imgLine3to4.svg";
import imgLine4to5 from "./../img/imgLine4to5.svg";
import img1Careers from "./../img/img1Careers.svg";
import img2Careers from "./../img/img2Careers.svg";
import img3Careers from "./../img/img3Careers.svg";
import img4Careers from "./../img/img4Careers.svg";
import img5Careers from "./../img/img5Careers.svg";
import imgVerticalCareers from "./../img/imgVerticalCareers.svg";
import imgArrowMore from "./../img/imgArrowMore.svg";
import imgBenefits1 from "./../img/imgBenefits1.svg";
import imgBenefits2 from "./../img/imgBenefits2.svg";
import imgBenefits3 from "./../img/imgBenefits3.svg";
import imgBenefits4 from "./../img/imgBenefits4.svg";
import imgViktoriyaKopeyka from "./../img/imgViktoriyaKopeyka.svg";
import imgViktoriyaKopeyka2 from "./../img/imgViktoriyaKopeyka2.svg";
import imgViktoriyaKopeyka3 from "./../img/imgViktoriyaKopeyka3.svg";
import imgViktoriyaKopeyka4 from "./../img/imgViktoriyaKopeyka4.svg";
import imgLogoLinkedin from "./../img/imgLogoLinkedin.svg";
import imgPineapple1 from "./../img/imgPineapple1.svg";
import "./../css/Successfully.scss";
import imgSuccessfully from "./../img/imgSuccessfully.svg";
import loading from "./../img/loading.gif";

function CareersPage(props) {

  const [showFormCV, setShowFormCV] = useState(false);
  const [showSuccessfully, setShowSuccessfully] = useState(false);
  const [inputName, setInputName] = useState('');
  const [inputEmail, setInputEmail] = useState('');
  const [selectOption, setSelectOption] = useState("position0");
  const [inputMessage, setInputMessage] = useState('');
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [checkboxOption, setCheckboxOption] = useState(false);
  // const [inputNameStart, setInputNameStart] = useState(true);
  // const [inputEmailStart, setInputEmailStart] = useState(true);
  // const [selectOptionStart, setSelectOptionStart] = useState(true);
  // const [fileStart, setFileStart] = useState(true);
  // const [checkboxOptionStart, setCheckboxOptionStart] = useState(true);
  const [inputNameLabelClassName, setInputNameLabelClassName] = useState("errorEmptyValue");
  const [inputEmailLabelClassName, setInputEmailLabelClassName] = useState("errorEmptyValue");
  const [selectOptionLabelClassName, setSelectOptionLabelClassName] = useState("errorEmptyValue");
  // const [fileLabelClassName, setFileLabelClassName] = useState("errorEmptyValue");
  const [checkboxOptionLabelClassName] = useState("errorEmptyValue");
  const [inputMessageLabelClassName, setInputMessageLabelClassName] = useState("errorEmptyValue");
  // const [checkboxOptionClassName, setCheckboxOptionClassName] = useState("errorEmptyValue");
  const [fileClassName, setFileClassName] = useState("errorEmptyValue");
  const [formErrors, setFormErrors] = useState({
    inputName: '',
    inputEmail: '',
    selectOption: '',
    file: '',
    checkboxOption: '',
  });
  const [inputNameValid, setInputNameValid] = useState(false);
  const [inputEmailValid, setInputEmailValid] = useState(false);
  const [selectOptionValid, setSelectOptionValid] = useState(false);
  const [fileValid, setFileValid] = useState(false);
  const [checkboxOptionValid, setCheckboxOptionValid] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [careersInfo, setCareersInfo] = useState(false);
  const [careerLoading, setCareerLoading] = useState(false);
  const [allNameCareers, setAllNameCareers] = useState('');

  const [careers1WD, setCareers1WD] = useState('');
  const [careers2WD, setCareers2WD] = useState('');
  const [careers3WD, setCareers3WD] = useState('');
  const [careers4WD, setCareers4WD] = useState('');
  const [careers5WD, setCareers5WD] = useState('');
  const [careers6WD, setCareers6WD] = useState('');
  const [careers7WD, setCareers7WD] = useState('');

  useEffect(() => {
    if( props.wd < 1200 ){
      setCareers1WD(careers11x);
      setCareers2WD(careers21x);
      setCareers3WD(careers31x);
      setCareers4WD(careers41x);
      setCareers5WD(careers51x);
      setCareers6WD(careers61x);
      setCareers7WD(careers71x);
    } else if( props.wd >= 1200 ){
      setCareers1WD(careers12x);
      setCareers2WD(careers22x);
      setCareers3WD(careers32x);
      setCareers4WD(careers42x);
      setCareers5WD(careers52x);
      setCareers6WD(careers62x);
      setCareers7WD(careers72x);
    }
  },[props.wd]);

  const getAllCareersInfo = async () => {
    // await axios
    //     .get("/api/careers.php?getAllNameCareers=true")
    //     .then(({ data }) => {
    //       setAllNameCareers(data.careersName);
    //     });
    await axios
        .get("/vacancies")
        .then(({ data }) => {
          const answer = data;
          setCareersInfo(answer.data);
          setAllNameCareers(answer.data);
          setCareerLoading(false);
        });
  }

  useEffect(getAllCareersInfo, [])

  const hendleOpenForm = (e) => {
    setShowFormCV(true);
  };

  const hendleCloseForm = (e) => {
    setShowFormCV(false);
  };

  const hendleCloseSuccessfully = (e) => {
    setShowSuccessfully(false);
  };

  const onSubmit = () => {
    validateField('inputName', inputName);
    validateField('inputEmail', inputEmail);
    validateField('selectOption', selectOption);
    validateField('file', file);
    validateField('checkboxOption', checkboxOption);

    validateForm();


    if(formValid){
      const formData = new FormData();

      formData.append("name", inputName);
      formData.append("email", inputEmail);
      formData.append("selectOption", selectOption);
      formData.append("file", selectedFile);
      formData.append("message", inputMessage);
      formData.append("page", "careers");

      axios.post('/send-cv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      setShowFormCV(false);
      setShowSuccessfully(true);

      setTimeout(() => {
        setShowSuccessfully(false);
        setInputName("");
        setInputEmail("");
        setSelectOption("position0");
        setFile("");
        setSelectedFile("");
        setCheckboxOption(false);
        setInputMessage("");
      }, 3000);
    }
  };

  const handleUserInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if(name==='checkboxOption'){
      value = e.target.checked;
    }
    else if ( name ==='file' ){
      // event.target.files[0],
      let fileValue = e.target.files[0];
      setSelectedFile(fileValue);
      setFileName(e.target.files[0].name);
    }
    if(name === 'inputName'){
      setInputName(value);
    }
    else if(name === 'inputEmail'){
      setInputEmail(value);
    }
    else if(name === 'selectOption'){
      setSelectOption(value);
    }
    else if(name === 'inputMessage'){
      setInputMessage(value);
    }
    else if(name === 'file'){
      setFile(value);
    }
    else if(name === 'checkboxOption'){
      setCheckboxOption(value);
    }
    validateField(name, value);
  }

  const validateField = (fieldName, value) => {

    let fieldValidationErrors = formErrors;

    let inputNameValidVal = inputNameValid;
    let inputEmailValidVal = inputEmailValid;
    let selectOptionValidVal = selectOptionValid;
    let fileValidVal = fileValid;
    let checkboxOptionValidVal = checkboxOptionValid;

    switch(fieldName) {
      case 'inputName':
        inputNameValidVal = value.length >= 1;
        if(inputNameValidVal){
          fieldValidationErrors.inputName = '';
          setInputNameLabelClassName("errorNotEmptyValue");
        } else {
          fieldValidationErrors.inputName = 'Enter name';
          if(!value.trim()){
            setInputNameLabelClassName("errorRed errorEmptyValue errorRedBorder");
          } else {
            setInputNameLabelClassName("errorRed errorNotEmptyValue errorRedBorder");
          }
        }
        break;
      case 'inputEmail':
        inputEmailValidVal = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if(inputEmailValidVal){
          fieldValidationErrors.inputEmail = '';
          setInputEmailLabelClassName("errorNotEmptyValue");
        } else {
          fieldValidationErrors.inputEmail = 'This email is not correct!';
          if(!value.trim()){
            setInputEmailLabelClassName("errorRed errorEmptyValue errorRedBorder");
          } else {
            setInputEmailLabelClassName("errorRed errorNotEmptyValue errorRedBorder");
          }
        }
        break;
      case 'selectOption':
        selectOptionValidVal = value !== 'position0' ? true : false;
        if(selectOptionValidVal){
          fieldValidationErrors.selectOption = '';
          setSelectOptionLabelClassName("errorNotEmptyValue");
        } else {
          fieldValidationErrors.selectOption = 'Select position';
          setSelectOptionLabelClassName("errorRed errorEmptyValue errorRedBorder");
        }
        break;
      case 'inputMessage':
        fieldValidationErrors.inputMessage = true;
        if(value){
          setInputMessageLabelClassName("errorNotEmptyValue");
        } else {
          setInputMessageLabelClassName("errorEmptyValue");
        }
        break;
      case 'file':
        fileValidVal = value ? true : false;
        if(fileValidVal){
          fieldValidationErrors.file = '';
          setFileClassName("");
        } else {
          setFileClassName("errorRedBorder");
          fieldValidationErrors.file = '';
        }
        break;
      case 'checkboxOption':
        checkboxOptionValidVal = value;
        if(checkboxOptionValidVal){
          fieldValidationErrors.checkboxOption = '';
          // setCheckboxOptionClassName("errorNotEmptyValue");
        } else {
          // setCheckboxOptionClassName("errorEmptyValue");
          fieldValidationErrors.checkboxOption = 'You need to accept the policies.';
        }
        break;
      default:
        break;
    }
    setFormErrors(fieldValidationErrors);
    setInputNameValid(inputNameValidVal);
    setInputEmailValid(inputEmailValidVal);
    setSelectOptionValid(selectOptionValidVal);
    setFileValid(fileValidVal);
    setCheckboxOptionValid(checkboxOptionValidVal);
    validateForm();
  }

  const validateForm = () => {
    setFormValid(
        inputNameValid &&
        inputEmailValid &&
        selectOptionValid &&
        fileValid &&
        checkboxOptionValid
    );
  }

  const onClickButton = (event) => {
    //event.preventDefault();
    onSubmit();
  }

  return (
      <div>
        {/* Successfully */}
        {showSuccessfully ? (
            <Container>
              <Row>
                <Col>
                  <div
                      className="wrapSuccessfullyBG"
                      onClick={hendleCloseSuccessfully}
                  >
                    <div
                        className="wrapSuccessfully"
                        onClick={hendleCloseSuccessfully}
                    >
                      <h2
                          className="h2Successfully"
                          onClick={hendleCloseSuccessfully}
                      >
                        successfully
                      </h2>
                      <p
                          className="pSuccessfully"
                          onClick={hendleCloseSuccessfully}
                      >
                        Your message has been sent successfully
                      </p>
                      <img
                          src={imgSuccessfully}
                          alt=""
                          className="imgSuccessfully"
                          onClick={hendleCloseSuccessfully}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
        ) : null}
        {/* Successfully */}
        {showFormCV ? (
                //start form CV
                <div className="wrapFormSendCVBG">
                  <div className="wrapFormSendCV">
                    <Container>
                      <Row>
                        <Col>
                          <div className="wrapImgCloseButton">
                            <img
                                src={closeButton}
                                alt=" "
                                className="imgCloseButton"
                                onClick={hendleCloseForm}
                            />
                          </div>
                          <div className="formSendCVApplicationForm">
                            Application form
                          </div>
                          <Form
                              className="formSendCV"
                              id="formlead"
                              onSubmit={(e) => {onSubmit(); e.preventDefault();}}
                              // enctype="multipart/form-data"
                          >
                            {/* start inputName */}
                            <Row className="wrapFormRow">
                              <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                <div className="form-group">
                                  <input
                                      className={formErrors.inputName ? "form-control errorRedBorder" : "form-control"}
                                      type="text"
                                      name="inputName"
                                      placeholder=""
                                      value={inputName}
                                      onChange={handleUserInput}
                                  />
                                  <label
                                      className={inputNameLabelClassName}
                                      htmlFor="exampleInputEmail1">
                                    Name*
                                  </label>
                                  <div className="error errorInput">{formErrors.inputName}</div>
                                </div>
                              </Col>
                            </Row>
                            {/* end inputName */}
                            {/* start inputEmail */}
                            <Row className="wrapFormRow">
                              <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                <div className="form-group">
                                  <input
                                      className={formErrors.inputEmail ? "form-control errorRedBorder" : "form-control"}
                                      type="email"
                                      name="inputEmail"
                                      placeholder=""
                                      value={inputEmail}
                                      onChange={handleUserInput}
                                  />
                                  <label
                                      className={inputEmailLabelClassName}
                                      htmlFor="exampleInputEmail1">
                                    Email*
                                  </label>
                                  <div className="error errorInput">{formErrors.inputEmail}</div>
                                </div>
                              </Col>
                            </Row>
                            {/* end inputEmail */}
                            {/* start selectOption */}
                            <Row className="wrapFormRow">
                              <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                <div className="form-group">
                                  <select
                                      className={formErrors.selectOption ? "form-control errorRedBorder" : "form-control"}
                                      id="selectOption"
                                      name="selectOption"
                                      defaultValue={selectOption}
                                      onChange={handleUserInput}
                                  >
                                    <option value="position0"></option>
                                    {
                                      allNameCareers.map((function(item, i){
                                        return <option value={item.name}>{item.name}</option>
                                      }))
                                    }
                                  </select>
                                  <label
                                      className={selectOptionLabelClassName}
                                      htmlFor="exampleInputEmail1">
                                    Interested position*
                                  </label>
                                  <div className="error errorInput">{formErrors.selectOption}</div>
                                </div>
                              </Col>
                            </Row>
                            {/* end selectOption */}
                            {/* start inputMessage */}
                            <Row className="wrapFormRow">
                              <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                <div className="form-group">
                          <textarea
                              className="form-control"
                              name="inputMessage"
                              value={inputMessage}
                              onChange={handleUserInput}
                          >
                          </textarea>
                                  <label
                                      className={inputMessageLabelClassName}
                                      htmlFor="exampleInputEmail1">
                                    Message
                                  </label>
                                </div>
                              </Col>
                            </Row>
                            {/* end inputMessage */}
                            {/* start attach resume cv */}
                            <Row className="wrapFormRow">
                              <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                <div className="form-group">
                                  <input
                                      className={fileClassName}
                                      type="file"
                                      name="file"
                                      id="file"
                                      value={file}
                                      // onChange={onChangeHandler}
                                      onChange={handleUserInput}
                                  />
                                </div>
                                <div className="form-group">{fileName}</div>
                              </Col>
                            </Row>
                            {/* end attach resume cv */}
                            {/* start checkboxOption */}
                            <Row className="wrapFormRow">
                              <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                <div className="form-group">
                                  <input
                                      id="cbOption1"
                                      type="checkbox"
                                      className="form-control"
                                      name="checkboxOption"
                                      value={checkboxOption}
                                      onChange={handleUserInput}
                                  />
                                  <label
                                      id="cbOption1Label"
                                      className={checkboxOptionLabelClassName}
                                      htmlFor="cbOption1">
                                    I have read and agree to the <a href="/en/privacy-policy">Privacy Policy</a>
                                  </label>
                                  <div className="error errorInput errorCheckboxInput">{formErrors.checkboxOption}</div>
                                </div>
                              </Col>
                            </Row>
                            {/* end attach checkboxOption */}
                            <Button
                                variant="primary"
                                type="submit"
                                className="btn buttonOne buttonOneForm"
                                onClick={onClickButton}
                            >
                              Send
                            </Button>
                          </Form>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
            ) : //end formik
            null}
        {
          careerLoading ?
              <div className="wrapImgLoading">
                <img className="imgLoading" src={loading} alt="" title=""/>
              </div>
              : null
        }
        <Container>
          <div className="wrapBG">
            <Row className="wrapRowWeAreLookingOurPeople">
              <Col xl="5" lg="5" md="12" sm="12">
                <ReactWOW delay="1s" animation="slideInUp">
                  <div className="bigTextWeAreLookingOurPeople animate__animated animate__delay-1s animate__fadeInLeft">
                    <span className="colorAzure">Join</span> Us
                  </div>
                  <p className="pWeAreLookingOurPeople">
                    We invite talented people from all over Ukraine to join us. It doesn't matter in which part of our country you live - we appreciate the results and impact you can achieve.
                    <br/> Become a part of our Ukrainian team and move forward with us!
                  </p>
                </ReactWOW>
              </Col>
              <Col xl="7" lg="7" md="12" sm="12">
                <img
                    src={careers1WD}
                    alt=" "
                    title=" "
                    className="imgWeAreLookingOurPeople"
                />
              </Col>
            </Row>
            <Row className="wrapRowBuildYourFutureWithSagax">
              <Col xl="12" lg="12" md="12" sm="12">
                <div className="bigTextBuildYourFutureWithSagax animate__animated animate__delay-1s animate__fadeInLeft">
                  Build your future with Sagax
                </div>
                <p className="pBuildYourFutureWithSagax">
                  Sagax is more than just a job. We all are developing and learning rapidly, making mistakes sometimes, but still moving forward. We are close-knit team of like-minded people.
                </p>
                <Row className="wrapImgBuildYourFutureWithSagax">
                  <Col xl="5" lg="5" md="12" sm="12">
                    <img
                        src={careers2WD}
                        alt=" "
                        title=" "
                        className="imgBuildYourFutureWithSagax1"
                    />
                  </Col>
                  <Col xl="1" lg="1" md="12" sm="12">
                    <img
                        src={careers3WD}
                        alt=" "
                        title=" "
                        className="imgBuildYourFutureWithSagax2"
                    />
                  </Col>
                  <Col xl="6" lg="6" md="12" sm="12">
                    <img
                        src={careers4WD}
                        alt=" "
                        title=" "
                        className="imgBuildYourFutureWithSagax3"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="wrapRowRecruitmentAtSAGAXConsistsOfSuchSimpleStages">
              <Col xl="12" lg="12" md="12" sm="12">
                <ReactWOW delay="1s" animation="slideInUp">
                  <div className="bigTextRecruitmentAtSAGAXConsistsOfSuchSimpleStages animate__animated animate__delay-1s animate__fadeInLeft">
                    Simple Stages of SAGAX Recruitment
                  </div>
                </ReactWOW>
              </Col>
            </Row>
            <div className="wrapRowCareesImgBlockBG">
              <Row className="wrapRowCareesImgBlock">
                <Col
                    xl="12"
                    lg="12"
                    md="12"
                    sm="12"
                    className="careersImgBlock0"
                >
                  <img
                      src={imgLine1to2}
                      alt=" "
                      title=" "
                      className="imgLine1to2"
                  />
                </Col>
                <Col xl="4" lg="4" md="12" sm="12" className="careersImgBlock1">
                  <div className="careesImgBlockItem">
                    <p className="pCareesImgBlockItem pCareesImgBlockItem1">
                      Sending CV to the Company
                    </p>
                    <div className="go-corner" href="#">
                      <div className="go-arrow"></div>
                    </div>
                  </div>
                  <img
                      src={img1Careers}
                      alt=""
                      title=""
                      className="imgCareesImgBlockItem"
                  />
                </Col>
                <Col
                    xl="4"
                    lg="4"
                    md="12"
                    sm="12"
                    className="careersImgBlock12"
                ></Col>
                <Col xl="4" lg="4" md="12" sm="12" className="careersImgBlock2">
                  <div className="careesImgBlockItem">
                    <p className="pCareesImgBlockItem pCareesImgBlockItem2">
                      Telephone Call and HR Interview with Recruiter
                    </p>
                    <div className="go-corner" href="#">
                      <div className="go-arrow"></div>
                    </div>
                  </div>
                  <img
                      src={img2Careers}
                      alt=""
                      title=""
                      className="imgCareesImgBlockItem"
                  />
                </Col>
                <Col
                    xl="12"
                    lg="12"
                    md="12"
                    sm="12"
                    className="careersImgBlock23"
                >
                  <img
                      src={imgLine2to3}
                      alt=" "
                      title=" "
                      className="imgLine2to3"
                  />
                </Col>
                <Col xl="4" lg="4" md="12" sm="12" className="careersImgBlock3">
                  <div className="careesImgBlockItem">
                    <p className="pCareesImgBlockItem pCareesImgBlockItem3">
                      Interview with a Technical Expert and Interview with PM
                    </p>
                    <div className="go-corner" href="#">
                      <div className="go-arrow"></div>
                    </div>
                  </div>
                  <img
                      src={img3Careers}
                      alt=""
                      title=""
                      className="imgCareesImgBlockItem"
                  />
                </Col>
                <Col
                    xl="4"
                    lg="4"
                    md="12"
                    sm="12"
                    className="careersImgBlock34"
                >
                  <img
                      src={imgLine3to4}
                      alt=" "
                      title=" "
                      className="imgLine3to4"
                  />
                </Col>
                <Col xl="4" lg="4" md="12" sm="12" className="careersImgBlock4">
                  <div className="careesImgBlockItem">
                    <p className="pCareesImgBlockItem pCareesImgBlockItem4">
                      Decision on Cooperation
                    </p>
                    <div className="go-corner" href="#">
                      <div className="go-arrow"></div>
                    </div>
                  </div>
                  <img
                      src={img4Careers}
                      alt=""
                      title=""
                      className="imgCareesImgBlockItem"
                  />
                </Col>
                <Col
                    xl="4"
                    lg="4"
                    md="12"
                    sm="12"
                    className="careersImgBlock35"
                ></Col>
                <Col xl="4" lg="4" md="12" sm="12" className="careersImgBlock5">
                  <div className="careesImgBlockItem">
                    <p className="pCareesImgBlockItem pCareesImgBlockItem5">
                      Cooperation Offer
                    </p>
                    <div className="go-corner" href="#">
                      <div className="go-arrow"></div>
                    </div>
                  </div>
                  <img
                      src={img5Careers}
                      alt=""
                      title=""
                      className="imgCareesImgBlockItem"
                  />
                </Col>
                <Col
                    xl="4"
                    lg="4"
                    md="12"
                    sm="12"
                    className="careersImgBlock54"
                >
                  <img
                      src={imgLine4to5}
                      alt=" "
                      title=" "
                      className="imgLine4to5"
                  />
                </Col>
              </Row>
            </div>
            <Row className="wrapRowCareesImgBlock768">
              <Col md="12" sm="12" className="careesImg1Block careesImg1Block768">
                <img
                    src={img1Careers}
                    alt=""
                    title=""
                    className="imgCareesImgBlockItem"
                />
                <div className="careesImgBlockItem">
                  <p className="pCareesImgBlockItem pCareesImgBlockItem1">
                    Sending CV to the company
                  </p>
                </div>
              </Col>
              <Col md="12" sm="12" className="careesImgDotBlock768">
                <img
                    src={imgVerticalCareers}
                    alt=""
                    title=""
                    className="imgVerticalCareers"
                />
              </Col>
              <Col md="12" sm="12" className="careesImg1Block careesImg2Block768">
                <img
                    src={img2Careers}
                    alt=""
                    title=""
                    className="imgCareesImgBlockItem"
                />
                <div className="careesImgBlockItem">
                  <p className="pCareesImgBlockItem pCareesImgBlockItem2">
                    Telephone Interview with HR Manager
                  </p>
                </div>
              </Col>
              <Col md="12" sm="12" className="careesImgDotBlock768">
                <img
                    src={imgVerticalCareers}
                    alt=""
                    title=""
                    className="imgVerticalCareers"
                />
              </Col>
              <Col md="12" sm="12" className="careesImg1Block careesImg3Block768">
                <img
                    src={img3Careers}
                    alt=""
                    title=""
                    className="imgCareesImgBlockItem"
                />
                <div className="careesImgBlockItem">
                  <p className="pCareesImgBlockItem pCareesImgBlockItem3">
                    Interview with a technical expert and interview with PM
                  </p>
                </div>
              </Col>
              <Col md="12" sm="12" className="careesImgDotBlock768">
                <img
                    src={imgVerticalCareers}
                    alt=""
                    title=""
                    className="imgVerticalCareers"
                />
              </Col>
              <Col md="12" sm="12" className="careesImg1Block careesImg4Block768">
                <img
                    src={img4Careers}
                    alt=""
                    title=""
                    className="imgCareesImgBlockItem"
                />
                <div className="careesImgBlockItem">
                  <p className="pCareesImgBlockItem pCareesImgBlockItem4">
                    Decision on cooperation
                  </p>
                </div>
              </Col>
              <Col md="12" sm="12" className="careesImgDotBlock768">
                <img
                    src={imgVerticalCareers}
                    alt=""
                    title=""
                    className="imgVerticalCareers"
                />
              </Col>
              <Col md="12" sm="12" className="careesImg1Block careesImg5Block768">
                <img
                    src={img5Careers}
                    alt=""
                    title=""
                    className="imgCareesImgBlockItem"
                />
                <div className="careesImgBlockItem">
                  <p className="pCareesImgBlockItem pCareesImgBlockItem5">
                    Cooperation offer
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="wrapRowButtonSendCv">
              <Col xl="12" lg="12" md="12" sm="12">
                <ReactWOW delay="1s" animation="slideInUp">
                  <button
                      onClick={hendleOpenForm}
                      type="button"
                      className="btn buttonOne buttonOneSendCv animate__animated animate__delay-1s animate__fadeInLeft"
                  >
                    Send cv
                  </button>
                </ReactWOW>
              </Col>
            </Row>
            <Row className="wrapRowVacancies">
              <Col xl="12" lg="12" md="12" sm="12">
                <ReactWOW delay="1s" animation="slideInUp">
                  <div className="bigTextVacancies animate__animated animate__delay-1s animate__fadeInLeft">
                    Vacancies
                  </div>
                </ReactWOW>
              </Col>
            </Row>
            <Row className="wrapRowVacancies">
              <Col xl="12" lg="12" md="12" sm="12">
                <Tabs
                    defaultActiveKey="developers"
                    id="uncontrolled-tab-example"
                >
                  {/* start careersInfo.position_in_tab == 1  Developers */}
                  <Tab eventKey="developers" title="Developers">
                    <div className="wrapTabVacanciesBlock">
                      <div className="wrapItemVacanciesBlock">
                        {
                          careersInfo ?
                              careersInfo.map(((item)=>{
                                if(item.position.id === 1){
                                  return (
                                      <div className="itemVacanciesBlock">
                                        <a href={`/en/career/${item.id}`}>
                                          <h2 className="h2ItemVacanciesBlock">
                                            {item.name}
                                          </h2>
                                        </a>
                                        <p className="pItemVacanciesBlock">
                                          {item.short_description}
                                        </p>
                                        <div className="wrapVacanciesBlockMore">
                                          <a href={`/en/career/${item.id}`}>
                                            <img src={imgArrowMore} alt="" />
                                          </a>
                                        </div>
                                      </div>
                                  )
                                } else {
                                  return null;
                                }
                              }))
                              : null
                        }
                      </div>
                      <p className="pBottomItemVacanciesBlock">
                        If you don't find a suitable position at the moment, don't hesitate to send us your CV anyway. Your skills and expertise may be the perfect fit for future opportunities.
                      </p>
                      <button
                          onClick={hendleOpenForm}
                          type="button"
                          className="btn buttonOne animate__animated animate__delay-1s animate__fadeInLeft"
                      >
                        Send cv
                      </button>
                    </div>
                  </Tab>
                  {/* end careersInfo == 1  Developers */}
                  {/* start careersInfo == 2  Analysts */}
                  <Tab eventKey="analysts" title="Analysts">
                    <div className="wrapTabVacanciesBlock">
                      {
                        careersInfo ?
                            careersInfo.map(((item)=>{
                              if(item.position.id === 2){
                                return (
                                    <div className="itemVacanciesBlock">
                                      <a href={`/en/career/${item.id}`}>
                                        <h2 className="h2ItemVacanciesBlock">
                                          {item.name}
                                        </h2>
                                      </a>
                                      <p className="pItemVacanciesBlock">
                                        {item.short_description}
                                      </p>
                                      <div className="wrapVacanciesBlockMore">
                                        <a href={`/en/career/${item.id}`}>
                                          <img src={imgArrowMore} alt="" />
                                        </a>
                                      </div>
                                    </div>
                                )
                              } else {
                                return null;
                              }
                            }))
                            : null
                      }
                      <p className="pBottomItemVacanciesBlock">
                        Don’t see something that fits you at this time? Send us
                        your CV, anyway. Our team is always on the lookout to
                        add creative thinkers and collaborators.
                      </p>
                      <button
                          onClick={hendleOpenForm}
                          type="button"
                          className="btn buttonOne animate__animated animate__delay-1s animate__fadeInLeft"
                      >
                        Send cv
                      </button>
                    </div>
                  </Tab>
                  {/* end careersInfo == 2  Analysts */}
                  {/* start careersInfo == 3  Managers */}
                  <Tab eventKey="managers" title="Managers">
                    <div className="wrapTabVacanciesBlock">
                      {
                        careersInfo ?
                            careersInfo.map(((item)=>{
                              if(item.position.id === 3){
                                return (
                                    <div className="itemVacanciesBlock">
                                      <a href={`/en/career/${item.id}`}>
                                        <h2 className="h2ItemVacanciesBlock">
                                          {item.name}
                                        </h2>
                                      </a>
                                      <p className="pItemVacanciesBlock">
                                        {item.short_description}
                                      </p>
                                      <div className="wrapVacanciesBlockMore">
                                        <a href={`/en/career/${item.id}`}>
                                          <img src={imgArrowMore} alt="" />
                                        </a>
                                      </div>
                                    </div>
                                )
                              } else {
                                return null;
                              }
                            }))
                            : null
                      }
                      <p className="pBottomItemVacanciesBlock">
                        Don’t see something that fits you at this time? Send us
                        your CV, anyway. Our team is always on the lookout to
                        add creative thinkers and collaborators.
                      </p>
                      <button
                          onClick={hendleOpenForm}
                          type="button"
                          className="btn buttonOne animate__animated animate__delay-1s animate__fadeInLeft"
                      >
                        Send cv
                      </button>
                    </div>
                  </Tab>
                  {/* end careersInfo == 3  Managers */}
                </Tabs>
              </Col>
            </Row>
            <Row className="wrapRowBenefitsImg">
              <Col xl="12" lg="12" md="12" sm="12">
                <Row className="wrapImgBenefitsImg">
                  <Col xl="5" lg="5" md="12" sm="12">
                    <img
                        src={careers5WD}
                        alt=" "
                        title=" "
                        className="imgBuildYourFutureWithSagax4"
                    />
                  </Col>
                  <Col xl="1" lg="1" md="12" sm="12">
                    <img
                        src={careers6WD}
                        alt=" "
                        title=" "
                        className="imgBuildYourFutureWithSagax5"
                    />
                  </Col>
                  <Col xl="6" lg="6" md="12" sm="12">
                    <img
                        src={careers7WD}
                        alt=" "
                        title=" "
                        className="imgBuildYourFutureWithSagax6"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="wrapRowVacanciesBenefits">
              <Col xl="12" lg="12" md="12" sm="12">
                <ReactWOW delay="1s" animation="slideInUp">
                  <div className="bigTextVacancies animate__animated animate__delay-1s animate__fadeInLeft">
                    Benefits
                  </div>
                </ReactWOW>
              </Col>
            </Row>
            <Row className="wrapRowVacanciesBenefitsInfo">
              <Col xl="3" lg="6" md="6" sm="12">
                <div className="wrapVacanciesBenefits">
                  <img src={imgBenefits1} alt="" className="imgBenefits" />
                  <h2 className="h2Benefits">Flexibility</h2>
                  <p className="pBenefits">
                    Opt for a format that suits you best: work entirely remotely, or embrace a combination of working from home and the office. We prioritize your comfort and productivity, enabling you to create your ideal work environment.
                  </p>
                </div>
              </Col>
              <Col xl="3" lg="6" md="6" sm="12">
                <div className="wrapVacanciesBenefits">
                  <img src={imgBenefits2} alt="" className="imgBenefits" />
                  <h2 className="h2Benefits">Insurance</h2>
                  <p className="pBenefits">
                    Gain access to Life and Medical insurance plans, ensuring continuous protection. Our wide range of options guarantees the coverage necessary for your life and medical requirements, offering reassurance and stability whenever you need it.
                  </p>
                </div>
              </Col>
              <Col xl="3" lg="6" md="6" sm="12">
                <div className="wrapVacanciesBenefits">
                  <img src={imgBenefits3} alt="" className="imgBenefits" />
                  <h2 className="h2Benefits">Team Events</h2>
                  <p className="pBenefits">
                    Enjoy our continuous lineup of interactive events alongside our annual corporate gatherings. Join us and be part of a team that values engagement, fun, and a lively work environment!
                  </p>
                </div>
              </Col>
              <Col xl="3" lg="6" md="6" sm="12">
                <div className="wrapVacanciesBenefits">
                  <img src={imgBenefits4} alt="" className="imgBenefits" />
                  <h2 className="h2Benefits">No dress code</h2>
                  <p className="pBenefits">
                    Express yourself and be comfortable just as you are. We encourage individuality and welcome you to be yourself without the constraints of a dress code.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="wrapRowWeAreWaitingForYou">
              <Col xl="12" lg="12" md="12" sm="12">
                <Row className="wrapRowWeAreWaitingForYou2">
                  {/*<Col xl="3" lg="3" md="12" sm="12">*/}
                  {/*  <div className="imgViktoriyaKopeyka">*/}
                  {/*    <img*/}
                  {/*        src={imgViktoriyaKopeyka}*/}
                  {/*        alt=""*/}
                  {/*        className="imgViktoriyaK1"*/}
                  {/*    />*/}
                  {/*    <img*/}
                  {/*        src={imgViktoriyaKopeyka2}*/}
                  {/*        alt=""*/}
                  {/*        className="imgViktoriyaK2"*/}
                  {/*    />*/}
                  {/*    <img*/}
                  {/*        src={imgViktoriyaKopeyka3}*/}
                  {/*        alt=""*/}
                  {/*        className="imgViktoriyaK3"*/}
                  {/*    />*/}
                  {/*    <img*/}
                  {/*        src={imgViktoriyaKopeyka4}*/}
                  {/*        alt=""*/}
                  {/*        className="imgViktoriyaK4"*/}
                  {/*    />*/}
                  {/*    <div className="divImgViktoriyaKopeykaSS">*/}
                  {/*      <a href="https://www.linkedin.com/in/viktoriya-kopiyka-1b0b9baa/" target="_blank" rel="noopener noreferrer" className="aimgLogoLinkedin">*/}
                  {/*        <img*/}
                  {/*            src={imgLogoLinkedin}*/}
                  {/*            alt=""*/}
                  {/*            className="imgViktoriyaKopeykaSS"*/}
                  {/*        />*/}
                  {/*      </a>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</Col>*/}
                  {/*<Col xl="3" lg="3" md="6" sm="12">*/}
                  {/*  <div className="WeAreWaitingForYouLeftInfo">*/}
                  {/*    <p className="pWaitingBold">Viktoriya Kopeyka</p>*/}
                  {/*    <p className="pWaitingGrey">HRD</p>*/}
                  {/*    <p className="pWaitingGrey"></p>*/}
                  {/*    <a href="mailto:info@sagax.software" className="aWaiting">*/}
                  {/*      info@sagax.software*/}
                  {/*    </a>*/}
                  {/*  </div>*/}
                  {/*</Col>*/}
                  <Col xl="12" lg="12" md="12" sm="12">
                    <div>
                      <h2 className="h2Waiting">WE ARE WAITING FOR YOU!</h2>
                      <img
                          src={imgPineapple1}
                          alt=""
                          className="imgPineapple1"
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
  );
}

export default CareersPage;
