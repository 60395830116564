import React, {useEffect, useState} from "react";
import ReactWOW from "react-wow";
import "./../css/contact.scss";
import { Container, Row, Col } from "react-bootstrap";
import FormFormik from "./../component/FormikLead";
import axios from "axios";

function ContactPage() {

  const [call, setCall] = useState(false);
  const [write, setWrite] = useState(false);
  const [join, setJoin] = useState(false);

  const getPageInfo = async () => {
    await axios
        .get("/page/contact")
        .then(({ data }) => {
          const page = data;
          // 404
          if (!page) {
            props.history.push("/404");
          } else {
            setCall(page.call);
            setWrite(page.write);
            setJoin(page.join);

          }
        });
  }

  useEffect(getPageInfo, []);

  return (
      <div>
        <Container className="pl-0 pr-0">
          <div className="wrapBG">
            <Row className="mt80px mb80px">
              <Col xl="12" lg="12" md="12" sm="12">
                <ReactWOW delay="1s" animation="slideInUp">
                  <h2 className="titleH2 mt-5 animate__animated animate__delay-1s animate__slideInUp">
                    Get in touch
                  </h2>
                </ReactWOW>
              </Col>
            </Row>
            <Row className="align-items-center firstBlockRow firstBlockRowContact">
              <Col xl="6" lg="6" md="12" sm="12" className="flexTop">
                <div className="wrapContactItems">
                  <div className="contactItemBig">
                    Call
                  </div>
                  <div className="contactItemSmall contactItemSmallIconCall">
                    <a href={`tel:${call}`}>{call}</a>
                  </div>
                </div>
                <div className="wrapContactItems">
                  <div className="contactItemBig">
                    Write
                  </div>
                  <div className="contactItemSmall contactItemSmallIconEmail">
                    <a href={`mailto:${write}`}>{write}</a>
                  </div>
                </div>
                <div className="wrapContactItems">
                  <div className="contactItemBig">
                    Join
                  </div>
                  <div className="contactItemSmall contactItemSmallIconLinkedin">
                    <a href={join} target="_blank" rel="noopener noreferrer">LinkedIn</a>
                  </div>
                </div>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" className="flexTop">
                <div className="contacts-wrap-form">
                  <FormFormik />
                </div>
              </Col>
            </Row>
            <Row className="mt80px mb40px ml10px mr10px">
              <Col xl="12" lg="12" md="12" sm="12">
                <p className="p3Contact">
                  Ukraine, Kyiv
                  <br /> 14, Bratska Avenue
                </p>
                <div className="wrapImgMap">
                  {/* <img src={imgMap} alt="" className="imgMap" /> */}
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2539.785220489543!2d30.519116615944338!3d50.463724094289105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce40e88595af%3A0x4c5c077bad6b12f3!2z0YPQuy4g0JHRgNCw0YLRgdC60LDRjywgMTQsINCa0LjQtdCyLCAwMjAwMA!5e0!3m2!1sru!2sua!4v1599544254286!5m2!1sru!2sua" width="100%" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0" title="myFrame"></iframe>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

  );
}

export default ContactPage;
