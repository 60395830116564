import React from "react";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
class Formtest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputName: "",
            inputPhone: "",
            inputEmail: "",
            inputMessage: "",

            formErrors: {
                inputName: '',
                inputPhone: '',
                inputEmail: '',
                // inputMessage: '',
            },
            inputNameValid: false,
            inputPhoneValid: false,
            inputEmailValid: false,
            // inputMessageValid: true,

            formValid: false
        }
    }

    state = {
        inputName: "",
        inputPhone: "",
        inputEmail: "",
        inputMessage: "",

        formErrors: {
            inputName: '',
            inputPhone: '',
            inputEmail: '',
            // inputMessage: "",
        },

        inputNameValid: false,
        inputPhoneValid: false,
        inputEmailValid: false,
        // inputMessageValid: true,

        formValid: false
    };

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState((state) => ({
                [name]: value
            }),
            () => { this.validateField(name, value) }
        );
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let inputNameValid = this.state.inputNameValid;
        let inputPhoneValid = this.state.inputPhoneValid;
        let inputEmailValid = this.state.inputEmailValid;
        // let inputMessageValid = this.state.inputMessageValid;


        switch(fieldName) {
            case 'inputName':
                inputNameValid = value.length >= 3;
                fieldValidationErrors.inputName = inputNameValid ? '' : 'Enter name';
                break;
            case 'inputPhone':
                inputPhoneValid = value.match(/^(\+?\d{0,2})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{2}\)?)?\s?-?\s?(\(?\d{2}\)?)?$/);
                fieldValidationErrors.inputPhone = inputPhoneValid ? '': 'Phone number is not valid';
                break;
            case 'inputEmail':
                inputEmailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.inputEmail = inputEmailValid ? '' : 'This email is not correct!';
                break;
            // case 'inputMessage':
            //     // inputMessageValid = value.length >= 6;
            //     fieldValidationErrors.inputMessage = true;
            //     break;
            default:
                break;
        }
        this.setState(
            {
                formErrors: fieldValidationErrors,
                inputNameValid: inputNameValid,
                inputPhoneValid: inputPhoneValid,
                inputEmailValid: inputEmailValid,
                // inputMessageValid: inputMessageValid
            }, this.validateForm);
    }

    validateForm() {
        this.setState(
            {
                formValid: this.state.inputNameValid &&
                    this.state.inputPhoneValid &&
                    this.state.inputEmailValid

            }
        );
    }


    onSubmit = (values, e) => {
        e.preventDefault();
        console.log("Saved Data ", JSON.parse(JSON.stringify(values)));

        console.log({
            name: values.name,
            phone: values.phone,
            email: values.email,
            message: values.message,
        });
        axios
            .post("/contact", {
                name: values.name,
                phone: values.phone,
                email: values.email,
                message: values.message,
            })
            .then((response) => {
                console.log(response);
            });

        this.setState((state) => ({
                inputName: "",
                inputPhone: "",
                inputEmail: "",
                inputMessage: "",
            })
        );

    };


    render() {
        //   const { inputName, inputPhone, inputEmail, inputMessage } = this.state;
        return (
            <div>
                <Container>
                    <Form className="formDiscuss" id="formlead" onSubmit={this.onSubmit.bind(this)}>
                        <Row className="mt-4 mb-4">
                            <Col xl="6" lg="6" md="12" sm="12" className="wrapFormCol">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="inputName"
                                        placeholder=""
                                        value={this.state.inputName}
                                        onChange={this.handleUserInput.bind(this)}
                                    />
                                    { this.state.inputName ? <label className="pSubscriber errorNotEmptyValue" htmlFor="exampleInputEmail1">Name*</label>:null}
                                    { !this.state.inputName ? <label className="errorRed errorEmptyValue" htmlFor="exampleInputEmail1">Name*</label>:null}

                                    <div className="error errorInput">{this.state.formErrors.inputName}</div>
                                </div>
                            </Col>
                            <Col xl="6" lg="6" md="12" sm="12" className="wrapFormCol">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="inputPhone"
                                        placeholder=""
                                        value={this.state.inputPhone}
                                        onChange={this.handleUserInput.bind(this)}
                                    />
                                    { this.state.inputPhone ? <label className="pSubscriber errorNotEmptyValue" htmlFor="exampleInputEmail1">Phone*</label>:null}
                                    { !this.state.inputPhone ? <label className="errorRed errorEmptyValue" htmlFor="exampleInputEmail1">Phone*</label>:null}

                                    <div className="error errorInput">{this.state.formErrors.inputPhone}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-4">
                            <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                <div className="form-group">
                                    <input
                                        className="form-control"
                                        type="email"
                                        name="inputEmail"
                                        placeholder=""
                                        value={this.state.inputEmail}
                                        onChange={this.handleUserInput.bind(this)}
                                    />
                                    { this.state.inputEmail ? <label className="pSubscriber errorNotEmptyValue" htmlFor="exampleInputEmail1">Email*</label>:null}
                                    { !this.state.inputEmail ? <label className="errorRed errorEmptyValue" htmlFor="exampleInputEmail1">Email*</label>:null}

                                    <div className="error errorInput">{this.state.formErrors.inputEmail}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-4 mb-4">
                            <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                <div className="form-group">
                                <textarea
                                    className="form-control"
                                    name="inputMessage"
                                    defaultValue={this.state.inputMessage}
                                    onChange={this.handleUserInput.bind(this)}
                                >
                                    {/* {this.state.inputMessage} */}
                                </textarea>
                                    { this.state.inputMessage ? <label className="pSubscriber errorNotEmptyValue" htmlFor="exampleInputEmail1">Message</label>:null}
                                    { !this.state.inputMessage ? <label className="errorRed errorEmptyValue" htmlFor="exampleInputEmail1">Message</label>:null}
                                </div>
                            </Col>
                        </Row>
                        <Button
                            variant="primary"
                            type="submit"
                            className="btn buttonOne buttonOneForm"
                        >
                            Send
                        </Button>
                    </Form>
                    {/* <div className="row">
                    <div className="col">
                        <form id="formDiscuss">
                            <div className="form-group">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="exampleInputName1" 
                                    aria-describedby="nameHelp" 
                                    value={inputName}
                                    onChange={this.handleInputNameChange}
                                    />
                                { inputName ? <label className="pSubscriber errorNotEmptyValue" htmlFor="exampleInputEmail1">Enter Name</label>:null}
                                { !inputName ? <label className="errorRed errorEmptyValue" htmlFor="exampleInputEmail1">Enter Name</label>:null}
                            </div>
                            <div className="form-group">
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    id="exampleInputEmail1" 
                                    aria-describedby="emailHelp" 
                                    // placeholder="Enter email"
                                    value={inputEmail}
                                    onChange={this.handleInputEmailChange}
                                    />
                                    
                                { inputEmail ? <label className="pSubscriber errorNotEmptyValue" htmlFor="exampleInputEmail1">Enter email</label>:null}
                                { !inputEmail ? <label className="errorRed errorEmptyValue" htmlFor="exampleInputEmail1">Enter email</label>:null}
                                
                            </div>
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
                                <label className="form-check-label" for="exampleCheck1">Check me out</label>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                        <h2>Имя</h2>
                        <h2>{inputName}</h2>
                        <h2>Email</h2>
                        <h2>{inputEmail}</h2>
                    </div>
                </div> */}
                </Container>
            </div>
        )
    }
}

export default Formtest;