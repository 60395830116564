import React from "react";
import "./../css/caseStudies.scss";
import { Container, Row, Col } from "react-bootstrap";

import imgChatBots from "./../img/imgChatBots.png";

import imgTechStacksSpring from "./../img/imgTechStacksSpring.svg";
import imgTechStacksJava from "./../img/imgTechStacksJava.svg";
import imgTechStacksMicroservice from "./../img/imgTechStacksMicroservice.svg";
import imgTechStacksPostgresSql from "./../img/imgTechStacksPostgresSql.svg";
import imgTechStacksReact from "./../img/imgTechStacksReact.svg";
import imgCustomerProblems from "./../img/imgCustomerProblems.svg";
import imgCustomerProblems2 from "./../img/imgCustomerProblems2.svg";
import imgNumber1 from "./../img/imgNumber1.svg";
import imgNumber2 from "./../img/imgNumber2.svg";
import imgNumber3 from "./../img/imgNumber3.svg";
import imgNumber4 from "./../img/imgNumber4.svg";
import imgNumber5 from "./../img/imgNumber5.svg";
import imgResult1 from "./../img/imgResult1.svg";
import imgResult2 from "./../img/imgResult2.svg";
import imgConclusion from "./../img/imgConclusion.svg";

const CaseStudiesPage = () => {
  return (
    <div>
      <Container>
        <div className="wrapBG">
          <Row className="wrapRowChatBotsText">
            <Col xl="6" lg="6" md="12" sm="12">
              <h2 className="h2ChatBotsText">
                <span className="h2ChatBotsTextBlue">Chat</span>Bots
              </h2>
              <p className="pChatBotsText">
                Development and implementation of chat solutions and chat bots
                to build the process of escorting clients of an insurance
                company in a contact center.
              </p>
              <a href="/en/contact" className="btn buttonOne">Contact us</a>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img src={imgChatBots} alt=" " className="imgChatBots" />
            </Col>
          </Row>
          <Row className="wrapRowTechStacksText">
            <Col xl="12" lg="12" md="12" sm="12">
              <h2 className="h2TechStacksText">Tech stacks</h2>
            </Col>
          </Row>
          <Row className="wrapRowTechStacksImg">
            <Col xl="12" lg="12" md="12" sm="12">
              <div className="wrapTechStacks">
                <img src={imgTechStacksSpring} alt="" />
              </div>
              <div className="wrapTechStacks">
                <img src={imgTechStacksJava} alt="" />
              </div>
              <div className="wrapTechStacks">
                <img src={imgTechStacksMicroservice} alt="" />
              </div>
              <div className="wrapTechStacks">
                <img src={imgTechStacksPostgresSql} alt="" />
              </div>
              <div className="wrapTechStacks wrapTechStacks5">
                <img src={imgTechStacksReact} alt="" />
              </div>
            </Col>
          </Row>
          <Row className="wrapRowCustomerProblems">
            <Col xl="6" lg="6" md="12" sm="12">
              <h2 className="h2CustomerProblems">Customer problems</h2> 
              <div className="wrapTextCustomerProblems">
                <p className="pCustomerProblems">
                  The goal of the client was to transfer some of the customer's
                  requests from the phone line to the chat line. The main
                  purpose of this approach is to relieve the call center
                  operators of the burden, automate handling and reduce the cost
                  of customer support.
                </p>
                <p className="pCustomerProblems">
                  The client's company had a variety of requests that can be
                  solved in the chat mode, for example:
                </p>
                <ul className="ulCustomerProblems">
                  <li>clarify insurance settlement status;</li>
                  <li>
                    make an appointment for a routine medical examination;
                  </li>
                  <li>check the branch address;</li>
                  <li>others.</li>
                </ul>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img
                src={imgCustomerProblems}
                alt=""
                className="imgCustomerProblems"
              />
            </Col>
          </Row>
          <Row className="wrapRowCustomerProblems2">
            <Col xl="6" lg="6" md="12" sm="12">
              <img
                src={imgCustomerProblems2}
                alt=""
                className="imgCustomerProblems2top"
              />
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <div className="wrapTextCustomerProblems">
                <p className="pCustomerProblems">
                  Such requests for the clients and the insurance company are
                  much easier, cheaper and faster to solve in the chat mode. A
                  number of other requests can also be automated.
                </p>
                <p className="pCustomerProblems">
                  It was important for the client to be able to customize their
                  unique business processes for processing chat rooms when
                  implementing such a solution:
                </p>
                <ul className="ulCustomerProblems">
                  <li>Routing chat rooms to appropriate user groups;</li>
                  <li>Appointment of chat officers, supervisors, observers;</li>
                  <li>
                    Chat history and segmentation of chat rooms depending on the
                    topic of conversation;
                  </li>
                  <li>Tracking the status of chat rooms.</li>
                </ul>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img
                src={imgCustomerProblems2}
                alt=""
                className="imgCustomerProblems2bottom"
              />
            </Col>
          </Row>
          <Row className="wrapRowDevelopmentProcessText">
            <Col xl="12" lg="12" md="12" sm="12">
              <h2 className="h2DevelopmentProcess">Development process</h2>
            </Col>
          </Row>
          <Row className="wrapRowDevelopmentProcessBlock">
            <Col xl="12" lg="12" md="12" sm="12">
              <div className="itemImgNumber itemImgNumber1">
                <img src={imgNumber1} alt="" className="imgNumber imgNumber1" />
                <p className="pDevelopmentProcess">
                  We agreed with the client on the general concept of the
                  solution and the main problems to be solved
                </p>
              </div>
              <div className="itemImgNumber itemImgNumber2">
                <img src={imgNumber2} alt="" className="imgNumber imgNumber2" />
                <p className="pDevelopmentProcess">
                  Every week there was a status meeting with the project sponsor
                  and key members of the working group
                </p>
              </div>
              <div className="itemImgNumber itemImgNumber3">
                <img src={imgNumber3} alt="" className="imgNumber imgNumber3" />
                <p className="pDevelopmentProcess">
                  Agreed on action plans, solution concepts, mokups
                </p>
              </div>
              <div className="itemImgNumber itemImgNumber4">
                <img src={imgNumber4} alt="" className="imgNumber imgNumber4" />
                <p className="pDevelopmentProcess">
                  Demonstration of interim development results
                </p>
              </div>
              <div className="itemImgNumber itemImgNumber5">
                <img src={imgNumber5} alt="" className="imgNumber imgNumber5" />
                <p className="pDevelopmentProcess">
                  Adjustment of plans for future solution scalability
                </p>
              </div>
            </Col>
          </Row>
          <Row className="wrapRowResultsText">
            <Col xl="12" lg="12" md="12" sm="12">
              <h2 className="h2DevelopmentProcess">Results</h2>
            </Col>
          </Row>
          <Row className="wrapRowResults1Block">
            <Col xl="6" lg="6" md="12" sm="12">
              <div className="wrapResults1">
                <p className="pResults1">
                  The development took 3 months. The team has developed a
                  solution that solved all the requirements identified by the
                  customer.
                </p>
                <p className="pResults1">
                  We trained client's employees and then launched the system for
                  production. <br />
                  As a result of the solution launch, 6-10% of the requests were
                  transferred to the chat line.
                </p>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img src={imgResult1} alt="" className="imgResult1" />
            </Col>
          </Row>
          <Row className="wrapRowResults2Block">
            <Col xl="6" lg="6" md="12" sm="12" className="wrapImgResult2Top">
              <img
                src={imgResult2}
                alt=""
                className="imgResult2 imgResult2BTop"
              />
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <div className="wrapResults2">
                <p className="pResults1">
                  It was also possible to build a new way of communication with
                  customers through which in the future client can continue to
                  set up new business processes, such as:
                </p>
                <ul className="ulCustomerProblems">
                  <li>Sales;</li>
                  <li>Resolution;</li>
                  <li>Marketing.</li>
                </ul>
                <p className="pResults1">
                  Further phases of the project development with closer
                  integration of the solution into the client's IT landscape to
                  enrich the client's experience and increase user convenience
                  are outlined.
                </p>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12" className="wrapImgResult2Bottom">
              <img
                src={imgResult2}
                alt=""
                className="imgResult2 imgResult2Bottom"
              />
            </Col>
          </Row>
          <Row className="wrapRowConclusion">
            <Col xl="6" lg="6" md="12" sm="12">
              <div className="wrapConclusionText">
                <h2 className="h2Conclusion">Conclusion</h2>
                <p className="pConclusion">
                  Based on forecasts, 47% of organizations will use chatbots by
                  2025, which may save up to 30% of customer support costs. With
                  the help of our solution we can customize functionality to the
                  needs of the client, and ready-made solutions do not allow us
                  to do this. Thus, this solution is suitable for any business
                  and is always ready to scale.
                </p>
                <a href="/en/contact" className="btn buttonOne">Contact us</a>
              </div>
            </Col>
            <Col xl="6" lg="6" md="12" sm="12">
              <img src={imgConclusion} alt="" className="imgConclusion" />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default CaseStudiesPage;
