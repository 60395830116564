import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./css/index.scss";
import axios from 'axios';

axios.defaults.baseURL = 'https://admin.sagax.software/api/en';
//axios.defaults.baseURL = 'https://demo.sagax.software/api/en';
//axios.defaults.baseURL = 'http://127.0.0.1:8000/api/en';

ReactDOM.render(<App />, document.getElementById("root"));
