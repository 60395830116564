import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./../../css/products.scss";

import sagacityinsuranceapi11x from "./../../images/sagacityinsuranceapi/sagacityinsuranceapi-1-1x.png"
import sagacityinsuranceapi12x from "./../../images/sagacityinsuranceapi/sagacityinsuranceapi-1-2x.png"
import sagacityinsuranceapi21x from "./../../images/sagacityinsuranceapi/sagacityinsuranceapi-2-1x.png"
import sagacityinsuranceapi22x from "./../../images/sagacityinsuranceapi/sagacityinsuranceapi-2-2x.png"
import sagacityinsuranceapi31x from "./../../images/sagacityinsuranceapi/sagacityinsuranceapi-3-1x.png"
import sagacityinsuranceapi32x from "./../../images/sagacityinsuranceapi/sagacityinsuranceapi-3-2x.png"
import sagacityinsuranceapi41x from "./../../images/sagacityinsuranceapi/sagacityinsuranceapi-4-1x.png"
import sagacityinsuranceapi42x from "./../../images/sagacityinsuranceapi/sagacityinsuranceapi-4-2x.png"
import sagacityinsuranceapi51x from "./../../images/sagacityinsuranceapi/sagacityinsuranceapi-5-1x.png"
import sagacityinsuranceapi52x from "./../../images/sagacityinsuranceapi/sagacityinsuranceapi-5-2x.png"

import imgPineapple1 from "./../../img/imgPineapple1.svg"

function InsuranceApiPlatform(props) {
	
	const [sagacityinsuranceapi1WD, setSagacityinsuranceapi1WD] = useState('');
	const [sagacityinsuranceapi2WD, setSagacityinsuranceapi2WD] = useState('');
	const [sagacityinsuranceapi3WD, setSagacityinsuranceapi3WD] = useState('');
	const [sagacityinsuranceapi4WD, setSagacityinsuranceapi4WD] = useState('');
	const [sagacityinsuranceapi5WD, setSagacityinsuranceapi5WD] = useState('');
	
   useEffect(() => {
	  if( props.wd < 1200 ){
		  setSagacityinsuranceapi1WD(sagacityinsuranceapi11x);
		  setSagacityinsuranceapi2WD(sagacityinsuranceapi21x);
		  setSagacityinsuranceapi3WD(sagacityinsuranceapi31x);
		  setSagacityinsuranceapi4WD(sagacityinsuranceapi41x);
		  setSagacityinsuranceapi5WD(sagacityinsuranceapi51x);
	  } else if( props.wd >= 1200 ){
		  setSagacityinsuranceapi1WD(sagacityinsuranceapi12x);
		  setSagacityinsuranceapi2WD(sagacityinsuranceapi22x);
		  setSagacityinsuranceapi3WD(sagacityinsuranceapi32x);
		  setSagacityinsuranceapi4WD(sagacityinsuranceapi42x);
		  setSagacityinsuranceapi5WD(sagacityinsuranceapi52x);
	  }
   },[props.wd]);

  return (
  
      <div>
        <Container>
          <div className="wrapBG">
            <Row className="wrapRowinsuranceApiPlatform1">
              <Col xl="6" lg="6" md="12" sm="12">
                <h1 className="h1insuranceApiPlatform h2insuranceApiPlatform1">
                  <span>Insurance</span><br/>Api Platform
                </h1>
                <p className="pProducts width80">
                  Sagacity Insurance API empowers you to seamlessly integrate your sales processes with your partners' infrastructure.
                </p>
                <a href="/en/contact" className="btn buttonOne aProduct">
                  Contact us
                </a>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img src={sagacityinsuranceapi1WD} alt="" className="imgSagacityInsuranceAPI1"/>
              </Col>
            </Row>
            <Row className="wrapRowinsuranceApiPlatform2">
              <Col xl="6" lg="6" md="12" sm="12" className="imfageTop">
                <img src={sagacityinsuranceapi2WD} alt="" className="imgSagacityInsuranceAPI2"/>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <h2 className="h2Products h2insuranceApiPlatform2">RAPID PRODUCT SETUP WITH OUR CONFIGURATION PANEL</h2>
                <p className="pProducts">
                  - Effortlessly create insurance products.
                  <br/> - Configure tariff calculation and validation rules.
                  <br/> - Establish your customized sales process.</p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" className="imfageBottom">
                <img src={sagacityinsuranceapi2WD} alt="" className="imgSagacityInsuranceAPI2"/>
              </Col>
            </Row>
            <Row className="wrapRowinsuranceApiPlatform3">
              <Col xl="6" lg="6" md="12" sm="12">
                <h2 className="h2Products h2insuranceApiPlatform3">READY-TO-USE API FOR STREAMLINING THE ENTIRE SALES PROCESS</h2>
                <p className="pProducts">Our API simplifies the following essential functions:
                  <br/> - Instant price calculation.
                  <br/> - Effortless coverage selection.
                  <br/> - Automated validation of product business rules.
                  <br/> - Online contract signing with ease.
                  <br/> - Quick generation of printouts.</p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img src={sagacityinsuranceapi3WD} alt="" className="imgSagacityInsuranceAPI3"/>
              </Col>
            </Row>
            <Row className="wrapRowinsuranceApiPlatform4">
            <Col xl="6" lg="6" md="12" sm="12" className="imfageTop">
                <img src={sagacityinsuranceapi4WD} alt="" className="imgSagacityInsuranceAPI2"/>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <h2 className="h2Products h2insuranceApiPlatform4">Sell a Full Range of Insurance Products Online</h2>
                <p className="pProducts">The Sagacity API is versatile and supports a wide array of product types, including Motor, Travel, Property, Gadget, Health, and more.</p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" className="imfageBottom">
                <img src={sagacityinsuranceapi4WD} alt="" className="imgSagacityInsuranceAPI2"/>
              </Col>
            </Row>
            <Row className="wrapRowinsuranceApiPlatform5">
              <Col xl="6" lg="6" md="12" sm="12">
                <h2 className="h2Products h2insuranceApiPlatform5">Set Up Business Processes to Support Your Sales</h2>
                <p className="pProducts">- Gain access to all essential data through the sales dashboard.
                  <br/>- Efficiently review proposals and contracts to streamline your sales operations.</p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img src={sagacityinsuranceapi5WD} alt="" className="imgSagacityInsuranceAPI5"/>
              </Col>
            </Row>
            <Row className="wrapRowProducts6">
              <Col xl="6" lg="6" md="12" sm="12">
                <img
                  src={imgPineapple1}
                  alt=""
                  className="imgPineappleProduct"
                />
                <h2 className="h2Products">Want to learn more?</h2>
                <p className="pProducts">
                  Tell us about your business needs. We will find the perfect
                  solution.
                </p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <a href="/en/contact" className="btn buttonOne buttonProduct">Contact us</a>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

  );
}

export default InsuranceApiPlatform;
