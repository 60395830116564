import React from "react";
import "./../css/careers.scss";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const FormSendCVPage = () => {
  return (
    <div>
      <Container>
        <div className="wrapBG">
          <Row className="wrapRowRowVacancyH1">
            <Col xl="12" lg="12" md="12" sm="12">
              <h2 className="h2FormSendCV">Application form</h2>
              <Form className="formDiscuss">
                <Form.Group controlId="formBasicName">
                  <Form.Control type="text" placeholder="Name" />
                </Form.Group>
                <Form.Group controlId="formBasicPhone">
                  <Form.Control type="text" placeholder="Phone" />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control type="email" placeholder="Email" />
                </Form.Group>
                <Form.Group controlId="formBasicMessage">
                  <Form.Control as="textarea" placeholder="Message" rows="3" />
                </Form.Group>
                <div key="idVacancyCheck" className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id="idVacancyCheck"
                    label="I have read and agree to the Privacy Policy"
                  />
                </div>
                <Button
                  variant="primary"
                  type="submit"
                  className="buttonOne buttonOneForm"
                >
                  Send
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default FormSendCVPage;
