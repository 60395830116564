import React from "react";
import "./../css/home.scss";

/* end new images */

import imgSlide1 from "./../img/tsslide1.png";
import imgSlide2 from "./../img/tsslide2.png";
import imgSlide3 from "./../img/tsslide3.png";
import imgSlide4 from "./../img/tsslide4.png";
import imgSlide5 from "./../img/tsslide5.png";
import imgSlide6 from "./../img/tsslide6.png";
import imgSlide7 from "./../img/tsslide7.png";
import imgSlide8 from "./../img/tsslide8.png";
import imgSlide9 from "./../img/tsslide9.png";
import imgSlide10 from "./../img/tsslide10.png";

function Techstacks(props) {

    return (
        <div className="wrapTS">
            <img className="itemts itemts1" src={imgSlide1} alt="" title="" />
            <img className="itemts itemts2" src={imgSlide2} alt="" title="" />
            <img className="itemts itemts3" src={imgSlide3} alt="" title="" />
            <img className="itemts itemts4" src={imgSlide4} alt="" title="" />
            <img className="itemts itemts5" src={imgSlide5} alt="" title="" />
            <img className="itemts itemts6" src={imgSlide6} alt="" title="" />
            <img className="itemts itemts7" src={imgSlide7} alt="" title="" />
            <img className="itemts itemts8" src={imgSlide8} alt="" title="" />
            <img className="itemts itemts9" src={imgSlide9} alt="" title="" />
            <img className="itemts itemts10" src={imgSlide10} alt="" title="" />
        </div>
    );
}

export default Techstacks;