import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import "./../css/blogs.scss";

import FormikSubscribe from "./../component/FormikSubscribe";
import blogArrow from "./../img/blogArrow.svg";
const Blogs = props => {
    const [blogs, setBlogs] = useState([]);
    const [blogLoading, setBlogLoading] = useState(true);

    useEffect(() => {
        getAllBlogs();
    },[blogLoading]);

    const getAllBlogs = async () => {
        await axios.get("/posts").then(({ data }) => {
            const answer = data;
            // 404
            if (!answer.data) {
                //props.history.push("/404");
                console.log("answer 404::", answer);
            } else {
                setBlogs(answer.data);
                setBlogLoading(false);
            }
        });
    }

    const getDateFormat = (dateitem) =>{
        if(dateitem){
            dateitem = String(dateitem);
            const months = {
                "01":'January',
                "02":'February',
                "03":'March',
                "04":'April',
                "05":'May',
                "06":'June',
                "07":'July',
                "08":'August',
                "09":'September',
                "10":'October',
                "11":'November',
                "12":'December'
            };

            let reYear = /(\d{4})\-\d{2}\-\d{2}.*/i;
            let reMonth = /\d{4}\-(\d{2})\-\d{2}.*/i;
            let reDay = /\d{4}\-\d{2}\-(\d{2}).*/i;

            let year = dateitem.match(reYear);
            year = (year[1])? year[1]:"2021";

            let day = dateitem.match(reDay);
            day = (day[1])? day[1]:"1";

            let monthName = dateitem.match(reMonth);
            monthName = (monthName[1])? monthName[1]:"0";
            monthName = months[monthName];

            return day + " " + monthName + " " + year;
        } else {
            return null
        }
    }
    return (
        <div>
            <Container>
                <div className="wrapBG">
                    <Row>
                        <Col>
                            <h1 className="h1Blogs">Blog</h1>
                        </Col>
                    </Row>
                    <Row className="wrapRowItemBlogs">
                        {blogs
                            ? blogs.map((item) => {
                                if (item.is_display === 1) {
                                    return (
                                        <Col
                                            xl="6"
                                            lg="6"
                                            md="12"
                                            sm="12"
                                            className="wrapBlogsItems"
                                        >
                                            <div className="wrapBlogsItem">
                                                <a className="wrapBlogsItemImg" href={`/en/blog/${item.id}`}>
                                                    <img src={item.img_link} alt="" />
                                                </a>
                                                <a className="wrapBlogsItemH2" href={`/en/blog/${item.id}`}>
                                                    <h2>{item.name}</h2>
                                                </a>
                                                <p className="wrapBlogsItemP">{item.short_description}</p>
                                                <div className="wrapInfoDateMore">
                              <span className="blogsDate">
                                {getDateFormat(item.date)}
                                  {/* {new Intl.DateTimeFormat("en-GB", {
                                  year: "numeric",
                                  month: "long",
                                  day: "2-digit",
                                }).format(new Date(item.date))} */}
                              </span>
                                                    <span className="blogsItemMore">
                                <div className="divBlogsItemMore">
                                  <div className="wrapDivBlogsItemMore">
                                    <a className="divBlogsItemMoreRead" href={`/en/blog/${item.id}`}>READ</a>
                                    <a className="divBlogsItemMoreArrow" href={`/en/blog/${item.id}`}>
                                      <img src={blogArrow} alt="" title="" />
                                    </a>

                                  </div>
                                </div>
                              </span>
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                } else {
                                    return null;
                                }
                            })
                            : null}
                    </Row>
                    <Row>
                        <Col>
                            <FormikSubscribe />
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default Blogs;
