import React, { useState, useEffect } from "react";
import "./../css/careers.scss";
import { Form, Container, Row, Col, Button } from "react-bootstrap";

import axios from "axios";
import imgSuccessfully from "./../img/imgSuccessfully.svg";
import closeButton from "./../img/closeButton.svg";

import imgViktoriyaKopeyka from "./../img/imgViktoriyaKopeyka.svg";
import imgViktoriyaKopeyka2 from "./../img/imgViktoriyaKopeyka2.svg";
import imgViktoriyaKopeyka3 from "./../img/imgViktoriyaKopeyka3.svg";
import imgViktoriyaKopeyka4 from "./../img/imgViktoriyaKopeyka4.svg";
import imgLogoLinkedin from "./../img/imgLogoLinkedin.svg";
import imgPineapple1 from "./../img/imgPineapple1.svg";
import loading from "./../img/loading.gif";

function P1AnalystsPage(props) {
    const [showFormCV, setShowFormCV] = useState(false);
    const [showSuccessfully, setShowSuccessfully] = useState(false);
    const [inputName, setInputName] = useState('');
    const [inputEmail, setInputEmail] = useState('');
    const [selectOption, setSelectOption] = useState("position0");
    const [inputMessage, setInputMessage] = useState('');
    const [file, setFile] = useState('');
    const [fileName, setFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState('');
    const [checkboxOption, setCheckboxOption] = useState(false);
    // const [inputNameStart, setInputNameStart] = useState(true);
    // const [inputEmailStart, setInputEmailStart] = useState(true);
    // const [selectOptionStart, setSelectOptionStart] = useState(true);
    // const [fileStart, setFileStart] = useState(true);
    // const [checkboxOptionStart, setCheckboxOptionStart] = useState(true);
    const [inputNameLabelClassName, setInputNameLabelClassName] = useState("errorEmptyValue");
    const [inputEmailLabelClassName, setInputEmailLabelClassName] = useState("errorEmptyValue");
    const [selectOptionLabelClassName, setSelectOptionLabelClassName] = useState("errorEmptyValue");
    // const [fileLabelClassName, setFileLabelClassName] = useState("errorEmptyValue");
    const [checkboxOptionLabelClassName] = useState("errorEmptyValue");
    const [inputMessageLabelClassName, setInputMessageLabelClassName] = useState("errorEmptyValue");
    // const [checkboxOptionClassName, setCheckboxOptionClassName] = useState("errorEmptyValue");
    const [fileClassName, setFileClassName] = useState("errorEmptyValue");
    const [formErrors, setFormErrors] = useState({
        inputName: '',
        inputEmail: '',
        selectOption: '',
        file: '',
        checkboxOption: '',
    });
    const [inputNameValid, setInputNameValid] = useState(false);
    const [inputEmailValid, setInputEmailValid] = useState(false);
    const [selectOptionValid, setSelectOptionValid] = useState(true);
    const [fileValid, setFileValid] = useState(false);
    const [checkboxOptionValid, setCheckboxOptionValid] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [careersInfoName, setCareersInfoName] = useState(false);
    const [careersInfoDescription, setCareersInfoDescription] = useState(false);
    const [careerId] = useState(props.match.params.id);
    const [careerLoading, setCareerLoading] = useState(true);
    const [allNameCareers, setAllNameCareers] = useState('');

    const getCareersInfo = async() => {
        await axios
            .get("/vacancies")
            .then(({ data }) => {
                setAllNameCareers(data.data);
            });
        await axios
            .get("/vacancies/"+careerId)
            .then(({ data }) => {
                const vacancy = data;
                // 404
                if(!vacancy){
                    props.history.push('/404');
                }
                else {
                    setCareersInfoName(vacancy.name)
                    setCareersInfoDescription(vacancy.description);
                    setCareerLoading(false);
                }
            });
    }
    useEffect(getCareersInfo, []);

    const hendleOpenForm = (e) => {
        setShowFormCV(true);
    };

    const hendleCloseForm = (e) => {
        setShowFormCV(false);
    };

    const hendleCloseSuccessfully = (e) => {
        setShowSuccessfully(false);
    };

    const onSubmit = () => {
        validateField('inputName', inputName);
        validateField('inputEmail', inputEmail);
        validateField('selectOption', selectOption);
        validateField('file', file);
        validateField('checkboxOption', checkboxOption);

        validateForm();

        if(formValid){
            const formData = new FormData();

            formData.append("name", inputName);
            formData.append("email", inputEmail);
            formData.append("selectOption", selectOption);
            formData.append("file", selectedFile);
            formData.append("message", inputMessage);
            formData.append("page", "career");

            axios.post('/send-cv', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            setShowFormCV(false);
            setShowSuccessfully(true)

            setTimeout(() => {
                setShowSuccessfully(false);
                setInputName("");
                setInputEmail("");
                setSelectOption("position0");
                setFile("");
                setSelectedFile("");
                setCheckboxOption(false);
                setInputMessage("");
            }, 3000);
        }
    };

    const handleUserInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(name==='checkboxOption'){
            value = e.target.checked;
        }
        if(name === 'inputName'){
            setInputName(value);
        }
        else if(name === 'inputEmail'){
            setInputEmail(value);
        }
        else if(name === 'selectOption'){
            setSelectOption(value);
        }
        else if(name === 'inputMessage'){
            setInputMessage(value);
        }
        else if(name === 'file'){
            let fileValue = e.target.files[0];
            setSelectedFile(fileValue);
            value = e.target.value;
            setFile(value);
            setFileName(e.target.files[0].name);
        }
        else if(name === 'checkboxOption'){
            setCheckboxOption(value);
        }
        validateField(name, value);
    }

    const validateField = (fieldName, value) => {

        let fieldValidationErrors = formErrors;
        let inputNameValidVal = inputNameValid;
        let inputEmailValidVal = inputEmailValid;
        let selectOptionValidVal = selectOptionValid;
        let fileValidVal = fileValid;
        let checkboxOptionValidVal = checkboxOptionValid;

        switch(fieldName) {
            case 'inputName':
                inputNameValidVal = value.length >= 1;
                if(inputNameValidVal){
                    fieldValidationErrors.inputName = '';
                    setInputNameLabelClassName("errorNotEmptyValue");
                } else {
                    fieldValidationErrors.inputName = 'Enter name';
                    if(!value.trim()){
                        setInputNameLabelClassName("errorRed errorEmptyValue errorRedBorder");
                    } else {
                        setInputNameLabelClassName("errorRed errorNotEmptyValue errorRedBorder");
                    }
                }
                break;
            case 'inputEmail':
                inputEmailValidVal = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                if(inputEmailValidVal){
                    fieldValidationErrors.inputEmail = '';
                    setInputEmailLabelClassName("errorNotEmptyValue");
                } else {
                    fieldValidationErrors.inputEmail = 'This email is not correct!';
                    if(!value.trim()){
                        setInputEmailLabelClassName("errorRed errorEmptyValue errorRedBorder");
                    } else {
                        setInputEmailLabelClassName("errorRed errorNotEmptyValue errorRedBorder");
                    }
                }
                break;
            case 'selectOption':
                selectOptionValidVal = value !== 'position0' ? true : false;
                if(selectOptionValidVal){
                    fieldValidationErrors.selectOption = '';
                    setSelectOptionLabelClassName("errorNotEmptyValue");
                } else {
                    fieldValidationErrors.selectOption = 'Select position';
                    setSelectOptionLabelClassName("errorRed errorEmptyValue errorRedBorder");
                }
                break;
            case 'inputMessage':
                fieldValidationErrors.inputMessage = true;
                if(value){
                    setInputMessageLabelClassName("errorNotEmptyValue");
                } else {
                    setInputMessageLabelClassName("errorEmptyValue");
                }
                break;
            case 'file':
                fileValidVal = value ? true : false;
                if(fileValidVal){
                    fieldValidationErrors.file = '';
                    setFileClassName("");
                } else {
                    setFileClassName("errorRedBorder");
                    fieldValidationErrors.file = '';
                }
                break;
            case 'checkboxOption':
                checkboxOptionValidVal = value;
                if(checkboxOptionValidVal){
                    fieldValidationErrors.checkboxOption = '';
                    // setCheckboxOptionClassName("errorNotEmptyValue");
                } else {
                    // setCheckboxOptionClassName("errorEmptyValue");
                    fieldValidationErrors.checkboxOption = 'You need to accept the policies.';
                }
                break;
            default:
                break;
        }

        setFormErrors(fieldValidationErrors);
        setInputNameValid(inputNameValidVal);
        setInputEmailValid(inputEmailValidVal);
        setSelectOptionValid(selectOptionValidVal);
        setFileValid(fileValidVal);
        setCheckboxOptionValid(checkboxOptionValidVal);
        validateForm();
    }

    const validateForm = () => {
        setFormValid(
            inputNameValid &&
            inputEmailValid &&
            selectOptionValid &&
            fileValid &&
            checkboxOptionValid
        );
    }

    const onClickButton = (event) => {
        event.preventDefault();
        onSubmit();
    }

    return (
        <div>
            {showSuccessfully ? (
                <Container>
                    <Row>
                        <Col>
                            <div
                                className="wrapSuccessfullyBG"
                                onClick={hendleCloseSuccessfully}
                            >
                                <div
                                    className="wrapSuccessfully"
                                    onClick={hendleCloseSuccessfully}
                                >
                                    <h2
                                        className="h2Successfully"
                                        onClick={hendleCloseSuccessfully}
                                    >
                                        successfully
                                    </h2>
                                    <p
                                        className="pSuccessfully"
                                        onClick={hendleCloseSuccessfully}
                                    >
                                        Your message has been sent successfully
                                    </p>
                                    <img
                                        src={imgSuccessfully}
                                        alt=""
                                        className="imgSuccessfully"
                                        onClick={hendleCloseSuccessfully}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            ) : null}
            {/* Successfully */}
            {showFormCV ? (
                    //start form CV
                    <div className="wrapFormSendCVBG">
                        <div className="wrapFormSendCV">
                            <Container>
                                <Row>
                                    <Col>
                                        <div className="wrapImgCloseButton">
                                            <img
                                                src={closeButton}
                                                alt=" "
                                                className="imgCloseButton"
                                                onClick={hendleCloseForm}
                                            />
                                        </div>
                                        <div className="formSendCVApplicationForm">
                                            Application form
                                        </div>
                                        <Form className="formSendCV" id="formlead" onSubmit={(e) => {onSubmit(); e.preventDefault();}}>
                                            {/* start inputName */}
                                            <Row className="wrapFormRow">
                                                <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                                    <div className="form-group">
                                                        <input
                                                            className={formErrors.inputName ? "form-control errorRedBorder" : "form-control"}
                                                            type="text"
                                                            name="inputName"
                                                            placeholder=""
                                                            value={inputName}
                                                            onChange={handleUserInput}
                                                        />
                                                        <label
                                                            className={inputNameLabelClassName}
                                                            htmlFor="exampleInputEmail1">
                                                            Name*
                                                        </label>
                                                        <div className="error errorInput">{formErrors.inputName}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* end inputName */}
                                            {/* start inputEmail */}
                                            <Row className="wrapFormRow">
                                                <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                                    <div className="form-group">
                                                        <input
                                                            className={formErrors.inputEmail ? "form-control errorRedBorder" : "form-control"}
                                                            type="email"
                                                            name="inputEmail"
                                                            placeholder=""
                                                            value={inputEmail}
                                                            onChange={handleUserInput}
                                                        />
                                                        <label
                                                            className={inputEmailLabelClassName}
                                                            htmlFor="exampleInputEmail1">
                                                            Email*
                                                        </label>
                                                        <div className="error errorInput">{formErrors.inputEmail}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* end inputEmail */}
                                            {/* start selectOption */}
                                            <Row className="wrapFormRow">
                                                <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                                    <div className="form-group">
                                                        <select
                                                            className={formErrors.selectOption ? "form-control errorRedBorder" : "form-control"}
                                                            id="selectOption"
                                                            name="selectOption"
                                                            defaultValue={selectOption}
                                                            onChange={handleUserInput}
                                                        >
                                                            <option value="position0"></option>
                                                            {
                                                                allNameCareers.map((function(item, i){
                                                                    return <option value={item.name}>{item.name}</option>
                                                                }))
                                                            }
                                                        </select>
                                                        <label
                                                            className={selectOptionLabelClassName}
                                                            htmlFor="exampleInputEmail1">
                                                            Interested position*
                                                        </label>
                                                        <div className="error errorInput">{formErrors.selectOption}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* end selectOption */}
                                            {/* start inputMessage */}
                                            <Row className="wrapFormRow">
                                                <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                                    <div className="form-group">
                      <textarea
                          className="form-control"
                          name="inputMessage"
                          value={inputMessage}
                          onChange={handleUserInput}
                      >
                      </textarea>
                                                        <label
                                                            className={inputMessageLabelClassName}
                                                            htmlFor="exampleInputEmail1">
                                                            Message
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* end inputMessage */}
                                            {/* start attach resume cv */}
                                            <Row className="wrapFormRow">
                                                <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                                    <div className="form-group">
                                                        <input
                                                            className={fileClassName}
                                                            type="file"
                                                            name="file"
                                                            id="file"
                                                            value={file}
                                                            onChange={handleUserInput}
                                                        />
                                                    </div>
                                                    <div className="form-group">{fileName}</div>
                                                </Col>
                                            </Row>
                                            {/* end attach resume cv */}
                                            {/* start checkboxOption */}
                                            <Row className="wrapFormRow">
                                                <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                                                    <div className="form-group">
                                                        <input
                                                            id="cbOption1"
                                                            type="checkbox"
                                                            className="form-control"
                                                            name="checkboxOption"
                                                            value={checkboxOption}
                                                            onChange={handleUserInput}
                                                        />
                                                        <label
                                                            id="cbOption1Label"
                                                            className={checkboxOptionLabelClassName}
                                                            htmlFor="cbOption1">
                                                            I have read and agree to the <a href="/en/privacy-policy">Privacy Policy</a>
                                                        </label>
                                                        <div className="error errorInput errorCheckboxInput">{formErrors.checkboxOption}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* end attach checkboxOption */}
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                className="btn buttonOne buttonOneForm"
                                                onClick={onClickButton}
                                            >
                                                Send
                                            </Button>
                                        </Form>

                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    //end form CV
                ) :
                null}

            {
                careerLoading ?
                    <div className="wrapImgLoading">
                        <img className="imgLoading" src={loading} alt="" title=""/>
                    </div>
                    : null
            }
            <Container>
                <div className="wrapBG">
                    <Row className="wrapRowRowVacancyH1">
                        <Col xl="12" lg="12" md="12" sm="12">
                            <div className="bigTextVacancy">Vacancy</div>
                            <div className="big2TextVacancy">
                                { careersInfoName ?? null }
                            </div>
                        </Col>
                    </Row>
                    <Row className="wrapRowVacancy">
                        <Col xl="12" lg="12" md="12" sm="12">
                            {careersInfoDescription ? <span dangerouslySetInnerHTML={{__html:careersInfoDescription}} /> : null }
                            <button
                                onClick={hendleOpenForm}
                                type="button"
                                className="btn buttonOne buttonOneVacancy buttonWaiting animate__animated animate__delay-1s animate__fadeInLeft"
                            >
                                Send cv
                            </button>
                        </Col>
                    </Row>
                    <Row className="wrapRowWeAreWaitingForYou">
                        <Col xl="12" lg="12" md="12" sm="12">
                            <Row className="wrapRowWeAreWaitingForYou2">

                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default P1AnalystsPage;
