import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import ReactWOW from "react-wow";
import { Link } from "react-scroll";
import "./../css/home.scss";
import { Container, Tab, Row, Col, Nav, CardDeck, Card } from "react-bootstrap";

import "react-multi-carousel/lib/styles.css";

import FormFormik from "./../component/FormikLead";
import Techstacks from "./../component/techstacks";

/* start new images */
import wehelp1x from "./../images/home/we-help-1x.png";
import wehelp2x from "./../images/home/we-help-2x.png";
import weoffer11x from "./../images/home/we-offer-1-1x.png";
import weoffer12x from "./../images/home/we-offer-1-2x.png";
import weoffer21x from "./../images/home/we-offer-2-1x.png";
import weoffer22x from "./../images/home/we-offer-2-2x.png";
import weoffer31x from "./../images/home/we-offer-3-1x.png";
import weoffer32x from "./../images/home/we-offer-3-2x.png";
import productsSlide11x from "./../images/home/productsSlide1-1x.png";
import productsSlide12x from "./../images/home/productsSlide1-2x.png";
import productsSlide21x from "./../images/home/productsSlide2-1x.png";
import productsSlide22x from "./../images/home/productsSlide2-2x.png";
import casestudies11x from "./../images/home/case-studies-1-1x.png";
import casestudies12x from "./../images/home/case-studies-1-2x.png";
import casestudies21x from "./../images/home/case-studies-2-1x.png";
import casestudies22x from "./../images/home/case-studies-2-2x.png";
import casestudies31x from "./../images/home/case-studies-3-1x.png";
import casestudies32x from "./../images/home/case-studies-3-2x.png";
import casestudies41x from "./../images/home/case-studies-4-1x.png";
import casestudies42x from "./../images/home/case-studies-4-2x.png";
import ourteam11x from "./../images/home/our-team-1-1x.png";
import ourteam12x from "./../images/home/our-team-1-2x.png";
import ourteam21x from "./../images/home/our-team-2-1x.png";
import ourteam22x from "./../images/home/our-team-2-2x.png";
import ourteam31x from "./../images/home/our-team-3-1x.png";
import ourteam32x from "./../images/home/our-team-3-2x.png";
import ourteam41x from "./../images/home/our-team-4-1x.png";
import ourteam42x from "./../images/home/our-team-4-2x.png";
import ourteam51x from "./../images/home/our-team-5-1x.png";
import ourteam52x from "./../images/home/our-team-5-2x.png";
import ourteam61x from "./../images/home/our-team-6-1x.png";
import ourteam62x from "./../images/home/our-team-6-2x.png";
import ourteam71x from "./../images/home/our-team-7-1x.png";
import ourteam72x from "./../images/home/our-team-7-2x.png";
import ourteam81x from "./../images/home/our-team-8-1x.png";
import ourteam82x from "./../images/home/our-team-8-2x.png";
import ourteam91x from "./../images/home/our-team-9-1x.png";
import ourteam92x from "./../images/home/our-team-9-2x.png";
import ourcustomers21x from "./../images/home/our-customers-2.svg";
import ourcustomers22x from "./../images/home/our-customers-2.svg";
import ourcustomers31x from "./../images/home/our-customers-3.png";
import ourcustomers32x from "./../images/home/our-customers-3.png";
/* end new images */

import imgCardOne from "./../img/imgCardOne.svg";
import imgCardTwo from "./../img/imgCardTwo.svg";
import imgCardThree from "./../img/imgCardThree.svg";
import imgCardFour from "./../img/imgCardFour.svg";
import formFigura1 from "./../img/formFigura1.svg";
import formFigura2 from "./../img/formFigura2.svg";
import formFigura3 from "./../img/formFigura3.svg";

function HomePage(props) {
  const [homeImgWD, setHomeImgWD] = useState('');
  const [imgWeOfferSlide1WD, setImgWeOfferSlide1WD] = useState('');
  const [imgWeOfferSlide2WD, setImgWeOfferSlide2WD] = useState('');
  const [imgWeOfferSlide3WD, setImgWeOfferSlide3WD] = useState('');
  const [productsSlide1WD, setProductsSlide1WD] = useState('');
  const [productsSlide2WD, setProductsSlide2WD] = useState('');
  const [casestudies1WD, setCasestudies1WD] = useState('');
  const [casestudies2WD, setCasestudies2WD] = useState('');
  const [casestudies3WD, setCasestudies3WD] = useState('');
  const [casestudies4WD, setCasestudies4WD] = useState('');
  const [ourteam1WD, setOurteam1WD] = useState('');
  const [ourteam2WD, setOurteam2WD] = useState('');
  const [ourteam3WD, setOurteam3WD] = useState('');
  const [ourteam4WD, setOurteam4WD] = useState('');
  const [ourteam5WD, setOurteam5WD] = useState('');
  const [ourteam6WD, setOurteam6WD] = useState('');
  const [ourteam7WD, setOurteam7WD] = useState('');
  const [ourteam8WD, setOurteam8WD] = useState('');
  const [ourteam9WD, setOurteam9WD] = useState('');

  const [ourcustomers2WD, setOurcustomers2WD] = useState('');
  const [ourcustomers3WD, setOurcustomers3WD] = useState('');
  // console.log("props widthDesctop::",props.wd);

  useEffect(() => {
    if( props.wd < 1200 ){
      setHomeImgWD(wehelp1x);
      setImgWeOfferSlide1WD(weoffer11x);
      setImgWeOfferSlide2WD(weoffer21x);
      setImgWeOfferSlide3WD(weoffer31x);
      setProductsSlide1WD(productsSlide11x);
      setProductsSlide2WD(productsSlide21x);
      setCasestudies1WD(casestudies11x);
      setCasestudies2WD(casestudies21x);
      setCasestudies3WD(casestudies31x);
      setCasestudies4WD(casestudies41x);
      setOurteam1WD(ourteam11x);
      setOurteam2WD(ourteam21x);
      setOurteam3WD(ourteam31x);
      setOurteam4WD(ourteam41x);
      setOurteam5WD(ourteam51x);
      setOurteam6WD(ourteam61x);
      setOurteam7WD(ourteam71x);
      setOurteam8WD(ourteam81x);
      setOurteam9WD(ourteam91x);
      setOurcustomers2WD(ourcustomers21x);
      setOurcustomers3WD(ourcustomers31x);
    } else if( props.wd >= 1200 ){
      setHomeImgWD(wehelp2x);
      setImgWeOfferSlide1WD(weoffer12x);
      setImgWeOfferSlide2WD(weoffer22x);
      setImgWeOfferSlide3WD(weoffer32x);
      setProductsSlide1WD(productsSlide12x);
      setProductsSlide2WD(productsSlide22x);
      setCasestudies1WD(casestudies12x);
      setCasestudies2WD(casestudies22x);
      setCasestudies3WD(casestudies32x);
      setCasestudies4WD(casestudies42x);
      setOurteam1WD(ourteam12x);
      setOurteam2WD(ourteam22x);
      setOurteam3WD(ourteam32x);
      setOurteam4WD(ourteam42x);
      setOurteam5WD(ourteam52x);
      setOurteam6WD(ourteam62x);
      setOurteam7WD(ourteam72x);
      setOurteam8WD(ourteam82x);
      setOurteam9WD(ourteam92x);
      setOurcustomers2WD(ourcustomers22x);
      setOurcustomers3WD(ourcustomers32x);
    }
  },[props.wd]);

  return (
      <div>
        <Container className="pl-0 pr-0">
          <div className="wrapBG">
            <Row className="align-items-center firstBlockRow">
              <Col xl="5" lg="5" md="12" sm="12">
                <div className="h1HomeTop bigText animate__animated animate__delay-1s animate__fadeInLeft">
                  <span className="colorAzure">Your</span> Trusted Insurance IT Provider
                </div>
                <p className="pHomeTop">
                  We provide a comprehensive portfolio of premium IT services, products, and solutions to meet the unique needs of our clients within the insurance industry.
                </p>
                <Link
                    to="idWrapForm"
                    smooth={true}
                    className="btn buttonOne animate__animated animate__delay-1s animate__fadeInLeft"
                >
                  Let's go
                </Link>
              </Col>
              <Col xl="7" lg="7" md="12" sm="12" className="firstBlockRowImg">
                <img
                    src={homeImgWD}
                    className="imgBlock1 animate__animated animate__delay-1s animate__fadeInRight"
                    alt=" "
                    title=" "
                />
              </Col>
            </Row>
            <Row className="mt-5 mb-5">
              <Col xl="12" lg="12" md="12" sm="12">
                <ReactWOW delay="1s" animation="slideInUp">
                  <h2 className="titleH2 mt-5 animate__animated animate__delay-1s animate__slideInUp">
                    We Offer
                  </h2>
                </ReactWOW>
              </Col>
            </Row>
            <Row className="wrapWeOffer mt-5 mb-5">
              <Tab.Container defaultActiveKey="software">
                <Row className="rowTabContainer">
                  <Col
                      xl="6"
                      lg="6"
                      md="12"
                      sm="12"
                      className="weOfferLeftPanel"
                  >
                    <ReactWOW delay="1s" animation="fadeInUp">
                      <Nav
                          variant="pills"
                          className="flex-column mt-5 animate__animated animate__delay-1s animate__fadeInUp"
                      >
                        <Nav.Item>
                          <Nav.Link className="textTab" eventKey="software">
                            <span>SOFTWARE DEVELOPMENT</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link className="textTab" eventKey="technology">
                            <span>TECHNOLOGY CONSULTATION</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link className="textTab" eventKey="product">
                            <span>PRODUCT DEVELOPMENT</span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </ReactWOW>
                  </Col>
                  <Col xl="6" lg="6" md="12" sm="12">
                    <Tab.Content className="mt-5">
                      <Tab.Pane
                          eventKey="software"
                          className=""
                      >
                        <img
                            src={imgWeOfferSlide1WD}
                            className="imgTab"
                            alt="Software Development"
                        />
                        <div className="wrapWeOfferText">
                          <h2 className="h2Tab">
                            SOFTWARE DEVELOPMENT
                          </h2>
                          <p className="pTab">
                            Our expert team will guide you through the entire project lifecycle, from the initial concept to a market-ready solution.
                          </p>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                          eventKey="technology"
                          className=""
                      >
                        <img
                            src={imgWeOfferSlide2WD}
                            className="imgTab"
                            alt="Mobile Development Services"
                        />
                        <h2 className="h2Tab">
                          TECHNOLOGY CONSULTATION
                        </h2>
                        <p className="pTab">
                          Our team of insurance domain experts and proficient development professionals collaboratively design optimal solutions tailored to our clients' needs.
                        </p>
                      </Tab.Pane>
                      <Tab.Pane
                          eventKey="product"
                          className=""
                      >
                        <img
                            src={imgWeOfferSlide3WD}
                            className="imgTab"
                            alt="UI/UX Design"
                        />
                        <h2 className="h2Tab">
                          PRODUCT DEVELOPMENT
                        </h2>
                        <p className="pTab">
                          We have created a suite of pre-built, industry-specific products designed to meet to the insurance sector's unique requirements.
                        </p>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Row>
            <Row className="wrapHomeProduct1">
              <Col>
                <h2 className="titleH2">
                  Products
                </h2>
              </Col>
            </Row>
            <div className="wrapBlockBGLeft">
              <Row className="align-items-center">
                <Container>
                  <Carousel className="wrapCarouselProduct">
                    <Carousel.Item>
                      <div className="wrapCaruselItem">
                        <img
                            className="caruselHomeProductSlide"
                            src={productsSlide1WD}
                            alt="carusel 1"
                        />
                        <Carousel.Caption className="wrapTextBlock">
                          <h3 className="h3Carusel">SAGACITY INSURANCE API PLATFORM</h3>
                          <p className="pCarusel">
                            Our Sagacity Insurance API Platform offers:
                            <br/>- Swift product configuration and setup.
                            <br/>- Pre-built APIs to streamline the entire sales process.
                            <br/>- The capability to market a comprehensive spectrum of insurance products online.
                            <br/>- The flexibility to establish and customize business processes to enhance your sales operations.
                          </p>
                          <a href="/en/product/sagacity-insurance-api" className="btn buttonOne buttonOneCarusel">
                            View more
                          </a>
                        </Carousel.Caption>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wrapCaruselItem">
                        <img
                            className="caruselHomeProductSlide"
                            src={productsSlide2WD}
                            alt="carusel 2"
                        />
                        <Carousel.Caption className="wrapTextBlock">
                          <h3 className="h3Carusel">SAGACITY INSURANCE ONLINE STORE</h3>
                          <p className="pCarusel">
                            Our Sagacity Insurance Online Store features:
                            <br/>- Rapid time to market, ensuring a swift product launch.
                            <br/>- A complete online sales process from start to finish.
                            <br/>- A comprehensive selection of insurance products.
                            <br/>- Product management empowered by a user-friendly low-code engine.
                          </p>
                          <a href="/en/product/online-insurance-products" className="btn buttonOne buttonOneCarusel">
                            View more
                          </a>
                        </Carousel.Caption>
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </Container>
              </Row>
              <Row className="wrapHomeProduct2">
                <Col xl="12" lg="12" md="12" sm="12">
                  <ReactWOW delay="1s" animation="slideInUp">
                    <h2 className="titleH2 mt-5 animate__animated animate__delay-1s animate__slideInUp">
                      Tech stacks
                    </h2>
                  </ReactWOW>
                </Col>
              </Row>
              <Row className="wrapRowTechStacksImg">
                <Col xl="12" lg="12" md="12" sm="12">
                  <div id="marquee1" className="marquee">
                    <div id="blockSlider" className="marquee__inner">
                      <Techstacks />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="wrapBlockBGRight">
              <Row className="wrapHomeProduct4">
                <Col xl="12" lg="12" md="12" sm="12">
                  <ReactWOW delay="1s" animation="slideInUp">
                    <h2 className="titleH2 animate__animated animate__delay-1s animate__slideInUp">
                      The way of cooperation
                      <br/>
                      or four steps to success
                    </h2>
                  </ReactWOW>
                </Col>
              </Row>
              <Row className="mt-5 mb-5">
                <Col xl="12" lg="12" md="12" sm="12">
                  <Container>
                    <CardDeck>
                      <Col xl="3" lg="3" md="6" sm="12" className="colWrapCardNumber">
                        <Card className="wrapCardNumber">
                          <ReactWOW delay="1s" animation="fadeInLeft">
                            <Card.Img
                                src={imgCardOne}
                                className="imgCardNumber animate__animated animate__delay-1s animate__fadeInLeft"
                                alt=" "
                            />
                          </ReactWOW>
                          <ReactWOW delay="1s" animation="fadeInUp">
                            <Card.Body className="wrapBodyCardNumber howItWorks1bg animate__animated animate__delay-1s animate__fadeInUp">
                              <Card.Title className="cardTitle">
                                1. Initialization
                              </Card.Title>
                              <Card.Text className="cardText">
                                The process starts with a thorough examination of your business idea. Our specialized Business Analyst will pinpoint your particular requirements and outline a development plan. After this plan is in place, we move forward with the development phase.
                              </Card.Text>
                            </Card.Body>
                          </ReactWOW>
                        </Card>
                      </Col>
                      <Col xl="3" lg="3" md="6" sm="12" className="colWrapCardNumber">
                        <Card className="wrapCardNumber">
                          <ReactWOW delay="1s" animation="fadeInLeft">
                            <Card.Img
                                src={imgCardTwo}
                                className="imgCardNumber animate__animated animate__delay-1s animate__fadeInLeft"
                                alt=" "
                            />
                          </ReactWOW>
                          <ReactWOW delay="1s" animation="fadeInUp">
                            <Card.Body className="wrapBodyCardNumber howItWorks2bg animate__animated animate__delay-1s animate__fadeInUp">
                              <Card.Title className="cardTitle">
                                2. Planning
                              </Card.Title>
                              <Card.Text className="cardText">
                                In this phase, we assemble a team with expertise in the relevant tech stacks for your project. We define project stages and control points, identifying potential risks and calculating the required resource allocation.
                              </Card.Text>
                            </Card.Body>
                          </ReactWOW>
                        </Card>
                      </Col>
                      <Col xl="3" lg="3" md="6" sm="12" className="colWrapCardNumber">
                        <Card className="wrapCardNumber">
                          <ReactWOW delay="1s" animation="fadeInLeft">
                            <Card.Img
                                src={imgCardThree}
                                className="imgCardNumber animate__animated animate__delay-1s animate__fadeInLeft"
                                alt=" "
                            />
                          </ReactWOW>
                          <ReactWOW delay="1s" animation="fadeInUp">
                            <Card.Body className="wrapBodyCardNumber howItWorks3bg animate__animated animate__delay-1s animate__fadeInUp">
                              <Card.Title className="cardTitle">
                                3. Development
                              </Card.Title>
                              <Card.Text className="cardText">
                                We offer flexible development approaches, including Agile (Scrum, Kanban) and Waterfall, tailored to your project's nature.
                                Throughout the development process, you can monitor progress and introduce changes to the plan as necessary.
                              </Card.Text>
                            </Card.Body>
                          </ReactWOW>
                        </Card>
                      </Col>
                      <Col xl="3" lg="3" md="6" sm="12" className="colWrapCardNumber">
                        <Card className="wrapCardNumber">
                          <ReactWOW delay="1s" animation="fadeInLeft">
                            <Card.Img
                                src={imgCardFour}
                                className="imgCardNumber animate__animated animate__delay-1s animate__fadeInLeft"
                                alt=" "
                            />
                          </ReactWOW>
                          <ReactWOW delay="1s" animation="fadeInUp">
                            <Card.Body className="wrapBodyCardNumber howItWorks4bg animate__animated animate__delay-1s animate__fadeInUp">
                              <Card.Title className="cardTitle">
                                4. Completion
                              </Card.Title>
                              <Card.Text className="cardText">
                                Following rigorous testing, we roll out the final product. You'll receive a detailed report on the work completed.
                                Our commitment extends beyond delivery, as we provide ongoing Technical Support to ensure the longevity and success of your product.
                              </Card.Text>
                            </Card.Body>
                          </ReactWOW>
                        </Card>
                      </Col>
                    </CardDeck>
                  </Container>
                </Col>
              </Row>
              <Row className="wrapHomeProduct5">
                <Col>
                  <h2 className="titleH2">
                    Case Studies
                  </h2>
                </Col>
              </Row>
              <Row className="align-items-center wrapRowCaruselItemCS">
                <Container>
                  <Carousel className="wrapCarouselCaseStudies">
                    <Carousel.Item>
                      <div className="wrapCaruselItemCS">
                        <img
                            className="caruselHomeCaseStudySlide"
                            src={casestudies1WD}
                            alt="carusel 1"
                        />
                        <Carousel.Caption className="wrapTextBlockCS">
                          <h3 className="h3Carusel">CHATBOTS</h3>
                          <p className="pCarusel">
                            Our team undertook the development and integration of chat solutions and chatbots to streamline the customer support process in a contact center for an insurance company.
                          </p>
                          <a href="/en/case-studies/chat-bots" className="btn buttonOne buttonOneCarusel">
                            View more
                          </a>
                        </Carousel.Caption>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wrapCaruselItemCS">
                        <img
                            className="caruselHomeCaseStudySlide"
                            src={casestudies2WD}
                            alt="carusel 1"
                        />
                        <Carousel.Caption className="wrapTextBlockCS">
                          <h3 className="h3Carusel">ONLINE STORE</h3>
                          <p className="pCarusel">
                            We developed and implemented an online solution for selling insurance contracts directly through the company's website, enhancing the accessibility and convenience of purchasing insurance products.
                          </p>
                          <a href="/en/case-studies/online-store" className="btn buttonOne buttonOneCarusel">
                            View more
                          </a>
                        </Carousel.Caption>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wrapCaruselItemCS">
                        <img
                            className="caruselHomeCaseStudySlide"
                            src={casestudies3WD}
                            alt="carusel 1"
                        />
                        <Carousel.Caption className="wrapTextBlockCS">
                          <h3 className="h3Carusel">API - SALES TOOL</h3>
                          <p className="pCarusel">
                            We created a tailored solution that leverages APIs to enhance and streamline the sales process of insurance products, providing a seamless experience for both clients and agents.
                          </p>
                          <a href="/en/case-studies/api-sales-tool" className="btn buttonOne buttonOneCarusel">
                            View more
                          </a>
                        </Carousel.Caption>
                      </div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <div className="wrapCaruselItemCS">
                        <img
                            className="caruselHomeCaseStudySlide"
                            src={casestudies4WD}
                            alt="carusel 2"
                        />
                        <Carousel.Caption className="wrapTextBlockCS">
                          <h3 className="h3Carusel">ONLINE AGENT</h3>
                          <p className="pCarusel">
                            Our team played a key role in implementing an innovative IT solution and a referral program to attract new customers for an insurance company. This unique approach facilitated customer acquisition and growth.
                          </p>
                          <a href="/en/product/online-insurance-products" className="btn buttonOne buttonOneCarusel">
                            View more
                          </a>
                        </Carousel.Caption>
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </Container>
              </Row>
              <Row className="mt-5 mb-5">
                <Col xl="12" lg="12" md="12" sm="12">
                  <ReactWOW delay="1s" animation="slideInUp">
                    <h2 className="titleH2 mt-5 animate__animated animate__delay-1s animate__slideInUp">
                      OUR TEAM
                    </h2>
                  </ReactWOW>
                  <ReactWOW delay="1s" animation="fadeInUp">
                    <p className="titleP animate__animated animate__delay-1s animate__fadeInUp">
                      Only an outstanding team can create outstanding software solutions
                    </p>
                  </ReactWOW>
                </Col>
              </Row>
              <div className="wrapBlockBGForm">
                <Row>
                  <Col xl="6" lg="6" md="6" sm="12">
                    {/* 1 */}
                    <Container>
                      <div className="wrap-conteiner-img">
                        <img
                            src={ourteam1WD}
                            alt=" "
                            className="cardImgItems"
                        />
                      </div>
                      <div className="wrap-conteiner-img">
                        <img
                            src={ourteam2WD}
                            alt=" "
                            className="cardImgItems"
                        />
                      </div>
                      <div className="wrap-conteiner-img">
                        <Card.Img
                            src={ourteam3WD}
                            alt=" "
                            className="cardImgItems"
                        />
                      </div>
                    </Container>
                    {/* 2 */}
                    <Container>
                      <div className="wrap-conteiner-img">
                        <img
                            src={ourteam4WD}
                            alt=" "
                            className="cardImgItems"
                        />
                      </div>
                      <div className="wrap-conteiner-img">
                        <img
                            src={ourteam5WD}
                            alt=" "
                            className="cardImgItems"
                        />
                      </div>
                      <div className="wrap-conteiner-img">
                        <img
                            src={ourteam6WD}
                            alt=" "
                            className="cardImgItems"
                        />
                      </div>
                    </Container>
                    {/* 3 */}
                    <Container>
                      <div className="wrap-conteiner-img">
                        <img
                            src={ourteam7WD}
                            alt=" "
                            className="cardImgItems"
                        />
                      </div>
                      <div className="wrap-conteiner-img">
                        <img
                            src={ourteam8WD}
                            alt=" "
                            className="cardImgItems"
                        />
                      </div>
                      <div className="wrap-conteiner-img">
                        <a href="/en/careers" className="imgOurTeam9">
                          <img
                              src={ourteam9WD}
                              alt=" "
                              className="cardImgItems"
                          />
                        </a>
                      </div>
                    </Container>
                  </Col>
                  <Col xl="6" lg="6" md="6" sm="12" className="wrapHomeProduct6">
                    <ReactWOW delay="1s" animation="fadeInUpBig">
                      <div className="animate__animated animate__delay-1s animate__fadeInUpBig">
                        <h3 className="h3OurTeam">WHO WE ARE</h3>
                        <p className="pOurTeam">
                          We are a software development company headquartered in Kyiv, Ukraine.
                        </p>
                        <p className="pOurTeam">
                          Our team comprises over 100 dedicated professionals, all in-house, who deliver innovative solutions driven by technology, creativity, and a passion for innovation.
                        </p>
                        <p className="pOurTeam">
                          Our deep expertise in cutting-edge technology and the intricacies of the insurance industry empowers us to guide our clients on their digitalization journey.
                        </p>
                        <a href="/en/careers" className="btn buttonOne">
                          View more
                        </a>
                      </div>
                    </ReactWOW>
                  </Col>
                </Row>


                <Row className="mt-5 mb-5">
                  <Col xl={{ span: 8, offset: 2 }} lg={{ span: 10, offset: 1 }} md="12" sm="12">
                    <ReactWOW delay="1s" animation="slideInUp">
                      <h2 className="titleH2 mt-5 animate__animated animate__delay-1s animate__slideInUp">
                        Our customers
                      </h2>
                    </ReactWOW>
                    <ReactWOW delay="1s" animation="fadeInUp">
                      <p className="titleP animate__animated animate__delay-1s animate__fadeInUp">
                        Partnering with the best
                      </p>
                    </ReactWOW>
                    <Row className="mt-5 mb-5 wrap-our-customers-card">
                      <Col xl="6" lg="6" md="12" sm="12">
                        <div className="wrap-our-customers-card-item">
                          <img
                              src={ourcustomers3WD}
                              className="our-customers-card-img our-customers-card-img3"
                              title=""
                              alt=" "
                          />
                        </div>
                      </Col>
                      <Col xl="6" lg="6" md="12" sm="12">
                        <div className="wrap-our-customers-card-item">
                          <img
                              src={ourcustomers2WD}
                              className="our-customers-card-img our-customers-card-img2"
                              title=""
                              alt=" "
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>


                <Row className="wrapForm" id="idWrapForm">
                  <Col
                      // xl={{ span: 8, offset: 2 }}
                      // lg={{ span: 10, offset: 1 }}
                      xl="12"
                      lg="12"
                      md="12"
                      sm="12"
                  >
                    <div className="wrapFormCard">
                      <ReactWOW delay="1s" animation="slideInUp">
                        <h2 className="titleH2 mt-5 animate__animated animate__delay-1s animate__slideInUp">
                          Let's discuss your ideas
                        </h2>
                      </ReactWOW>
                      <ReactWOW delay="1s" animation="flipInX">
                        <p className="titleP animate__animated animate__delay-1s animate__flipInX">
                          Simply fill the form or email us at info@sagax.software
                        </p>
                      </ReactWOW>
                      <ReactWOW delay="1s" animation="fadeInUpBig">
                        <img
                            className="formFigura1 animate__animated animate__delay-1s animate__fadeInUpBig"
                            src={formFigura1}
                            alt=" "
                        />
                      </ReactWOW>
                      <ReactWOW delay="1s" animation="fadeInTopLeft">
                        <img
                            className="formFigura3 animate__animated animate__delay-1s animate__fadeInTopLeft"
                            src={formFigura3}
                            alt=" "
                        />
                      </ReactWOW>
                      <FormFormik/>
                      <ReactWOW delay="1s" animation="fadeInTopRight">
                        <img
                            className="formFigura2 animate__animated animate__delay-1s animate__fadeInTopRight"
                            src={formFigura2}
                            alt=" "
                        />
                      </ReactWOW>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Container>
      </div>
  );
}

export default HomePage;
