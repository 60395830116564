import React, { useState, useEffect } from "react";
import "./../css/blogs.scss";
import { Container, Row, Col } from "react-bootstrap";

import axios from "axios";
import FormikSubscribe from "./../component/FormikSubscribe";
import loading from "./../img/loading.gif";

function Blog(props) {
    const [blogsInfoName, setBlogsInfoName] = useState(false);
    const [blogsInfoDescription, setBlogsInfoDescription] = useState(false);
    const [blogsInfoImage, setBlogsInfoImage] = useState(false);
    const [blogsInfoDate, setBlogsInfoDate] = useState(false);
    const [blogsInfoViewTime, setBlogsInfoViewTime] = useState(false);
    const [blogId] = useState(props.match.params.id);
    const [blogLoading, setBlogLoading] = useState(true);

    const getBlogInfo = async () => {
        await axios
            .get("/posts/" + blogId)
            .then(({ data }) => {
                const post = data;
                // 404
                if (!post) {
                    props.history.push("/404");
                } else {
                    setBlogsInfoName(post.name);
                    setBlogsInfoDate(post.date);
                    setBlogsInfoDescription(post.description);
                    setBlogsInfoImage(post.img_description_link);
                    setBlogLoading(false);
                    if(post.read_minutes){
                        setBlogsInfoViewTime(post.read_minutes + ' minutes ');
                    } else {
                        setBlogsInfoViewTime('0 minutes ');
                    }

                }
            });
        // setInterval(
        //   async () => {
        //     await axios.get("/api/blogs.php?viewCounterUp=true&id=" + blogId);
        //   }
        //   , 60000);
    }

    useEffect(getBlogInfo, [])

    const getDateFormat = (dateitem) =>{
        if(dateitem){
            dateitem = String(dateitem);
            const months = {
                "01":'January',
                "02":'February',
                "03":'March',
                "04":'April',
                "05":'May',
                "06":'June',
                "07":'July',
                "08":'August',
                "09":'September',
                "10":'October',
                "11":'November',
                "12":'December'
            };

            let reYear = /(\d{4})\-\d{2}\-\d{2}.*/i;
            let reMonth = /\d{4}\-(\d{2})\-\d{2}.*/i;
            let reDay = /\d{4}\-\d{2}\-(\d{2}).*/i;

            let year = dateitem.match(reYear);
            year = (year[1])? year[1]:"2021";

            let day = dateitem.match(reDay);
            day = (day[1])? day[1]:"1";

            let monthName = dateitem.match(reMonth);
            monthName = (monthName[1])? monthName[1]:"0";
            monthName = months[monthName];

            return day + " " + monthName + " " + year;
        } else {
            return null
        }

    }
    return (
        <div>
            {blogLoading ? (
                <div className="wrapImgLoading">
                    <img className="imgLoading" src={loading} alt="" title="" />
                </div>
            ) : null}
            <Container>
                <div className="wrapBG wrapBlogItem">
                    <Row>
                        <Col>
                            <h1 className="h1Blog">{blogsInfoName?blogsInfoName:null}</h1>
                            <p className="pDataBlog">
                                {getDateFormat(blogsInfoDate)}{" "}
                                | {blogsInfoViewTime} read
                            </p>
                            {blogsInfoImage ? (
                                <p><img src={blogsInfoImage} alt={blogsInfoName}/></p>
                            ) : null}

                            {blogsInfoDescription ? (
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: blogsInfoDescription
                                    }}
                                />
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormikSubscribe />
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default Blog;