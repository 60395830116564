import React from "react";
import "./../css/footer.scss";
import logo2 from "./../img/logo2.svg";
import { Container, Row, Col } from "react-bootstrap";
// import ScrollUpButton from "react-scroll-up-button";

const FrontFooter = () => {
  return (
    <div className="wrap-footer">
      <Container>
        <Row>
          <Col xl="6" lg="6" md="12" sm="12">
            <div>
              <img src={logo2} title="" alt="" className="logoFooter" />
              <div className="divFooterText">
                We are a dedicated software development company committed to assisting businesses in building and enhancing their software products.
              </div>
              <div className="divCopyFooter">
                Copyright © {(new Date().getFullYear())} SAGAX
                {/* ©{(new Date().getFullYear())}&nbsp;&nbsp;&nbsp;&nbsp;Privacy Policy */}
              </div>
            </div>
          </Col>
          <Col xl="2" lg="2" className="d-xl-block d-lg-block d-none">
            <p className="pBoldFooter">Products</p>
            <p className="pNormalFooter"><a href="/en/product/sagacity-insurance-api">Insurance Api Platform</a></p>
            <p className="pNormalFooter"><a href="/en/product/online-insurance-products">Insurance Online Store</a></p>
          </Col>
          <Col xl="2" lg="2" className="d-xl-block d-lg-block d-none">
            <p className="pBoldFooter">Case Studies</p>
            <p className="pNormalFooter"><a href="/en/case-studies/chat-bots">ChatBots</a></p>
            <p className="pNormalFooter"><a href="/en/case-studies/online-store">Online Store</a></p>
            <p className="pNormalFooter"><a href="/en/case-studies/api-sales-tool">API - Sales Tool</a></p>
            <p className="pNormalFooter"><a href="/en/case-studies/online-agent">Online Agent</a></p>
          </Col>
          <Col xl="2" lg="2" className="d-xl-block d-lg-block d-none">
            <p className="pBoldFooter"><a href="/en/company">Company</a></p>
            <p className="pBoldFooter"><a href="/en/careers">Careers</a></p>
            <p className="pBoldFooter"><a href="/en/blogs">Blog</a></p>
            <p className="pBoldFooter"><a href="/en/contact">Contacts</a></p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default FrontFooter;
