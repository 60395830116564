import React from "react";
import "./../css/p404.scss";
import { Container, Row, Col } from "react-bootstrap";
import img404whithFlower from "./../img/img404whithFlower.svg";

const P404Page = () => {
  return (
    <div className="wrap404">
      <Container>
        <div className="wrapBG">
          <Row>
            <Col>
              <div className="wrap404pageBGleft">
                <div className="wrap404pageDGright">
                  <h2 className="h2404">Oops! Why your here?</h2>
                  <div className="wrapButton404">
                    <a href="/en/contact" className="btn buttonOne">Contact us</a>
                  </div>
                  <img
                    src={img404whithFlower}
                    alt=" "
                    title=" "
                    className="img404whithFlower"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default P404Page;
