import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.scss";
import Header from "./layout/header";
import Footer from "./layout/footer";
import InsuranceOnlineStorePage from "./pages/products/insuranceOnlineStore.js";
import InsuranceApiPlatformPage from "./pages/products/insuranceApiPlatform.js";
import OnlineStorePage from "./pages/case-studies/online-store.js";
import ChatBotsPage from "./pages/case-studies/chat-bots.js";
import APISalesToolPage from "./pages/case-studies/api-sales-tool.js";
import OnlineAgentPage from "./pages/case-studies/online-agent.js";
import MobileAppPage from "./pages/case-studies/mobile-app.js";

import HomePage from "./pages/home.js";
import CompanyPage from "./pages/company.js";
import Contact from "./pages/contact.js";

import CaseStudies from "./pages/caseStudies.js";

import Careers from "./pages/careers.js";
import Career from "./pages/career.js";

import FormSendCV from "./pages/formSendCV";
import PrivacyPolicy from "./pages/privacy-policy";

import Blogs from "./pages/blogs.js";
import Blog from "./pages/blog.js";

import P404 from "./pages/404";

import { BrowserRouter, Route } from "react-router-dom";

import Formtest from "./pages/formtest.js";

function App() {
	const widthDesctop = window.innerWidth;
	console.log("widthDesctop==",widthDesctop);
  
  return (
	<BrowserRouter>
      <div className="App">
        
        <Header />
        <div>
          <Route exact path="/en" render={() => <HomePage wd={widthDesctop} />} />
          <Route
            path="/en/product/online-insurance-products"
            render={() => <InsuranceOnlineStorePage wd={widthDesctop} />}
          />
          <Route
            path="/en/product/sagacity-insurance-api"
            render={() => <InsuranceApiPlatformPage wd={widthDesctop} />}
          />
          <Route
            path="/en/case-studies/online-store"
            render={() => <OnlineStorePage wd={widthDesctop} />}
          />
          <Route
            path="/en/case-studies/chat-bots"
            render={() => <ChatBotsPage wd={widthDesctop} />}
          />
          <Route
            path="/en/case-studies/api-sales-tool"
            render={() => <APISalesToolPage wd={widthDesctop} />}
          />
          <Route
            path="/en/case-studies/online-agent"
            render={() => <OnlineAgentPage wd={widthDesctop} />}
          />
          <Route
            path="/en/case-studies/mobile-app"
            render={() => <MobileAppPage wd={widthDesctop} />}
          />

          <Route path="/en/company" render={() => <CompanyPage wd={widthDesctop} />} />
          <Route path="/en/contact" render={() => <Contact />} />
          <Route exact path="/en/case-studies" render={() => <CaseStudies />} />

          <Route exact path="/en/careers" render={() => <Careers wd={widthDesctop} />} />
          <Route path="/en/career/:id" render={(props) => <Career {...props} />} />

          <Route path="/en/blogs" render={() => <Blogs wd={widthDesctop} />} />
          <Route path="/en/blog/:id" render={(props) => <Blog {...props} />} />
          
          <Route path="/en/404" render={() => <P404 />} />

          <Route path="/en/formSendCV" render={() => <FormSendCV />} />
          <Route path="/en/privacy-policy" render={() => <PrivacyPolicy />} />

        </div>
        <Footer />
      </div>
    </BrowserRouter>
    );
}

export default App;
