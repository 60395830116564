import React from "react";
import "./../css/cookies.scss";
import cookies from "./../img/cookies.svg";
import closeCokies from "./../img/closeCokies.svg";

function CookiesPage(props) {

  return (
    <div className="wrapCookies">
      <div className="wrapCookiesBlockText">
        <div className="wrapCookiesBlockText1">
          <span>Cookies</span>
        </div>
        <div className="wrapCookiesBlockText2">
          <span><img src={cookies} alt=" "/></span>
        </div>
      </div>
      <div className="imgCloseCokies"><img src={closeCokies} onClick={props.onChangeHandler} alt=" "/></div>
      <div className="mainCookiesText">
        This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allow us to remember you. We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we use, see our Privacy Policy.
      </div>
      <div className="wrapButtonCookies">
        <button type="button" className="btn buttonOne buttonCookies" onClick={props.onChangeHandler}>
          Accept
        </button>
      </div>
        
    </div>
  );
};
export default CookiesPage;
