import React, { useEffect, useState } from "react";
import logo from "./../img/logo.svg";
import CookiesPage from "./cookies.js";
import "./../css/header.scss";
// import { NavLink } from "react-router-dom";Col,
import { Nav, Navbar, NavDropdown, Row, Container } from "react-bootstrap";
const FrontHeader = () => {
  const [showProducts, setShowProducts] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const [showCaseStudies, setShowCaseStudies] = useState(false);
  const [isCookies, setIsCookies] = useState(null);

  useEffect(()=>{

    // localStorage.setItem('isCookies', true);
    // localStorage.setItem('isUserEnter', true);
    setIsCookies(localStorage.getItem('isCookies'));
    //console.log('isCookies::',isCookies);
    if (isCookies) {
      
    }
  },[isCookies])

  const onChangeHandler = () => {
    localStorage.setItem('isCookies', true);
    setIsCookies(true);
  }

  return (
    <header className="header fixed-top bg-white">
      {
        isCookies === null ? 
        <CookiesPage isCookies={isCookies} onChangeHandler={onChangeHandler}/>
        : null
      }
      <Container className="pl-0 pr-0">
        <Row>
          <Navbar expand="lg" className="header-navbar">
            <Navbar.Brand href="/en/">
              <img src={logo} alt=" " className="topLogo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="navbar-right">
                <NavDropdown
                  className=""
                  title="Products"
                  id="basic-nav-dropdown-products"
                  show={showProducts}
                  onMouseEnter={()=>{setShowProducts(true)}} 
                  onMouseLeave={()=>{setShowProducts(false)}}
                >
                  <NavDropdown.Item href="/en/product/online-insurance-products">
                    Insurance Online Store
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/en/product/sagacity-insurance-api">
                    Insurance Api Platform
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  className=""
                  title="Case Studies"
                  id="basic-nav-dropdown-case-studies"
                  show={showCaseStudies}
                  onMouseEnter={()=>{setShowCaseStudies(true)}} 
                  onMouseLeave={()=>{setShowCaseStudies(false)}}
                >
                  <NavDropdown.Item href="/en/case-studies/chat-bots">
                    ChatBots
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/en/case-studies/online-store">
                    Online Store
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/en/case-studies/api-sales-tool">
                    API - Sales Tool
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/en/case-studies/online-agent">
                    Online Agent
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/en/case-studies/mobile-app">
                    Mobile App
                  </NavDropdown.Item>
                </NavDropdown>
                {/* <Nav.Link className="navLink" href="/en/case-studies">
                  Case Studies
                </Nav.Link> */}
                <Nav.Link className="navLink" href="/en/company">
                  Company
                </Nav.Link>
                <Nav.Link className="navLink" href="/en/careers">
                  Careers
                </Nav.Link>
                <Nav.Link className="navLink" href="/en/blogs">
                  Blog
                </Nav.Link>
                <Nav.Link className="navLink navLinkEnd" href="/en/contact">
                  Contacts
                </Nav.Link>
                <NavDropdown
                    className="basic-nav-dropdown-languages"
                    title="EN"
                    id="basic-nav-dropdown-languages"
                    show={showLanguages}
                    onMouseEnter={()=>{setShowLanguages(true)}}
                    onMouseLeave={()=>{setShowLanguages(false)}}
                >
                  <NavDropdown.Item href={location.pathname.replace(/\/en\//gi, "/ua/")}>
                    UA
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Row>
      </Container>
    </header>
  );
};
export default FrontHeader;
