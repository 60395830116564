import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./../../css/online-store.scss";

import "react-multi-carousel/lib/styles.css";
import Techstacks from "./../../component/techstacks";

import onlinestore11x from "./../../images/onlinestore/onlinestore-1-1x.png";
import onlinestore12x from "./../../images/onlinestore/onlinestore-1-2x.png";
import onlinestore21x from "./../../images/onlinestore/onlinestore-2-1x.png";
import onlinestore22x from "./../../images/onlinestore/onlinestore-2-2x.png";
import onlinestore31x from "./../../images/onlinestore/onlinestore-3-1x.png";
import onlinestore32x from "./../../images/onlinestore/onlinestore-3-2x.png";
import onlinestore41x from "./../../images/onlinestore/onlinestore-4-1x.png";
import onlinestore42x from "./../../images/onlinestore/onlinestore-4-2x.png";
import onlinestore51x from "./../../images/onlinestore/onlinestore-5-1x.png";
import onlinestore52x from "./../../images/onlinestore/onlinestore-5-2x.png";
import onlinestore61x from "./../../images/onlinestore/onlinestore-6-1x.png";
import onlinestore62x from "./../../images/onlinestore/onlinestore-6-2x.png";

import imgNumber1 from "./../../img/imgNumber1.svg";
import imgNumber2 from "./../../img/imgNumber2.svg";
import imgNumber3 from "./../../img/imgNumber3.svg";
import imgNumber4 from "./../../img/imgNumber4.svg";
import imgNumber5 from "./../../img/imgNumber5.svg";
import imgConclusion from "./../../img/imgConclusion.svg";

function OnlineStore(props) {

	const [onlinestore1WD, setOnlinestore1WD] = useState('');
	const [onlinestore2WD, setOnlinestore2WD] = useState('');
	const [onlinestore3WD, setOnlinestore3WD] = useState('');
	const [onlinestore4WD, setOnlinestore4WD] = useState('');
	const [onlinestore5WD, setOnlinestore5WD] = useState('');
	const [onlinestore6WD, setOnlinestore6WD] = useState('');
	
   useEffect(() => {
	  if( props.wd < 1200 ){
		  setOnlinestore1WD(onlinestore11x);
		  setOnlinestore2WD(onlinestore21x);
		  setOnlinestore3WD(onlinestore31x);
		  setOnlinestore4WD(onlinestore41x);
		  setOnlinestore5WD(onlinestore51x);
		  setOnlinestore6WD(onlinestore61x);
	  } else if( props.wd >= 1200 ){
		  setOnlinestore1WD(onlinestore12x);
		  setOnlinestore2WD(onlinestore22x);
		  setOnlinestore3WD(onlinestore32x);
		  setOnlinestore4WD(onlinestore42x);
		  setOnlinestore5WD(onlinestore52x);
		  setOnlinestore6WD(onlinestore62x);
	  }
   },[props.wd]);
  
  return (

      <div>
        <Container>
          <div className="wrapBG">
            <Row className="wrapRowOnlineStore1">
              <Col xl="5" lg="5" md="12" sm="12">
                <h2 className="h2OnlineStore">
                  <span>Online</span> Store
                </h2>
                <p className="pOnlineStore">
                  We specialize in the development and implementation of solutions that facilitate the sale of insurance contracts directly through a company's website, providing a seamless and convenient experience for customers.
                </p>
                <a href="/en/contact" className="btn buttonOne buttonProduct">Contact us</a>
              </Col>
              <Col xl="7" lg="7" md="12" sm="12">
                <img src={onlinestore1WD} alt="" className="imgOnlineStore1" />
              </Col>
            </Row>
            <Row className="wrapRowTechStacksText">
              <Col xl="12" lg="12" md="12" sm="12">
                <h2 className="h2TechStacksText">Tech stacks</h2>
              </Col>
            </Row>
            <Row className="wrapRowTechStacksImg">
              <Col xl="12" lg="12" md="12" sm="12">
                <div id="marquee1" className="marquee">
                  <div id="blockSlider" className="marquee__inner">
                    <Techstacks />
                  </div>
                </div>
              </Col>
            </Row>
            <div className="wrapRowDevelopmentProcessText row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12"><h2 className="h2DevelopmentProcess">CUSTOMER
                CHALLENGES</h2></div>
            </div>

            <Row className="wrapRowOnlineStore4">
              <Col xl="7" lg="7" md="12" sm="12">
                <p className="pCustomerProblems">
                  Our client aimed to establish a fully online sales channel for insurance, with Travel, MTPL, VTPL, and CASCO as the primary product offerings.
                  <br/>
                  <br/>
                  To create this online channel, the client required a solution that would enable them to:
                </p>
                <ul className="ulCustomerProblems">
                  <li> Configure insurance rules for selected insurance products.</li>
                  <li> Customize tariffs for precise price calculations.</li>
                  <li> Integrate various printout options.</li>
                  <li> Enable the online signing of insurance contracts.</li>
                  <li> Implement an online payment process for insurance contracts.</li>
                  <p className="pCustomerProblems">
                    Furthermore, the solution needed to be seamlessly integrated with the insurance company's IT infrastructure to utilize its data acquisition and verification services.
                  </p>
                </ul>
              </Col>
              <Col xl="5" lg="5" md="12" sm="12">
                <img
                    src={onlinestore2WD}
                    alt=""
                    className="imgCustomerProblems1"
                />
              </Col>
            </Row>
            <Row className="wrapRowOnlineStore5">
              <Col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  className="colImgCustomerProblems3Top"
              >
                <img
                    src={onlinestore3WD}
                    alt=""
                    className="imgCustomerProblems3"
                />
              </Col>
              <Col xl={{ offset: 1, span: 5 }} lg={{ offset: 1, span: 5 }} md="12" sm="12">
                <p className="pOnlineStore1">
                  For MTPL, the solution had to be integrated with the Motor Insurance Bureau of Ukraine (MTIBU) because online sales of these contracts were exclusively conducted through MTIBU. This integration aimed to ensure regulatory compliance and a streamlined user experience.
                  <br/><br/>
                  Recognizing that insurance products can be complex for the average individual, the solution was designed to simplify the sales process and provide clarity in both the purchase process and understanding the rules and conditions of insurance products.
                  <br/><br/>
                  Lastly, the solution was required to be fully branded to reflect the company's identity and visually integrated into the company's website for a cohesive and professional customer experience.
                </p>
              </Col>
              <Col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  className="colImgCustomerProblems3Bottom"
              >
                <img
                    src={onlinestore3WD}
                    alt=""
                    className="imgCustomerProblems3"
                />
              </Col>
            </Row>
            <Row className="wrapRowOnlineStore6">
              <Col>
                <h2 className="h2OnlineStore1">DEVELOPMENT PROCESS</h2>
              </Col>
            </Row>
            <Row className="wrapRowDevelopmentProcessBlock">
              <Col xl="12" lg="12" md="12" sm="12">
                <div className="itemImgNumber itemImgNumber1">
                  <img
                      src={imgNumber1}
                      alt=""
                      className="imgNumber imgNumber1"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Solution Concept and Problem Definition:</b>
                    <br/>- Collaborate with the client to establish the general concept of the solution and define the primary problems to be addressed. This step sets the foundation for the project.
                  </p>
                </div>
                <div className="itemImgNumber itemImgNumber2">
                  <img
                      src={imgNumber2}
                      alt=""
                      className="imgNumber imgNumber2"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Regular Status Meetings:</b>
                    <br/>- Conduct weekly status meetings with the project sponsor and key members of the working group. These meetings serve as a platform for ongoing communication and alignment with project goals.
                  </p>
                </div>
                <div className="itemImgNumber itemImgNumber3">
                  <img
                      src={imgNumber3}
                      alt=""
                      className="imgNumber imgNumber3"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Action Plans and Concept Agreements:</b>
                    <br/>- Develop action plans and agree upon solution concepts and mockups. This iterative process helps in creating a shared vision and understanding of the solution's direction.
                  </p>
                </div>
                <div className="itemImgNumber itemImgNumber4">
                  <img
                      src={imgNumber4}
                      alt=""
                      className="imgNumber imgNumber4"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Demonstrating Development Progress:</b>
                    <br/>Showcase intermediate development results to the client and project stakeholders. These demonstrations provide transparency and allow for early feedback and adjustments.
                  </p>
                </div>
                <div className="itemImgNumber itemImgNumber5">
                  <img
                      src={imgNumber5}
                      alt=""
                      className="imgNumber imgNumber5"
                  />
                  <p className="pDevelopmentProcess">
                    <b>Scalability Planning:</b>
                    <br/>Discuss and adjust scalability plans for the future of the solution. Scalability is crucial to accommodate growing demands and changes in the insurance industry.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="wrapRowOnlineStore7">
              <Col>
                <h2 className="h2OnlineStore1">RESULTS</h2>
              </Col>
            </Row>
            <Row className="wrapRowOnlineStore8">
              <Col xl="6" lg="6" md="12" sm="12">
                <p className="pOnlineStore1">
                  To attain our objectives, we configured and implemented several modules of the Sagacity CRM system, including:
                  <br />- Sagacity Web Store
                  <br />- Sagacity Insurance
                  <br />- Sagacity Business Rules
                </p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img
                    src={onlinestore4WD}
                    alt=""
                    className="imgResultsProduct1"
                />
              </Col>
            </Row>
            <Row className="wrapRowOnlineStore9">
              <Col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  className="colImgCustomerProblems3Top"
              >
                <img
                    src={onlinestore5WD}
                    alt=""
                    className="imgResultsProduct2"
                />
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <p className="pOnlineStore1">
                  We established the required insurance products, ensured that the Sagacity Web Store module adhered to the customer's BrandBook guidelines, and successfully implemented the solution.
                  <br />
                  <br />
                  The development phase spanned 4 months, during which the team effectively addressed all of the customer's specified requirements.
                  <br />
                  <br />
                  Following the development, we provided comprehensive training to the client's employees and subsequently launched the system for production use.
                </p>
              </Col>
              <Col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  className="colImgCustomerProblems3Bottom"
              >
                <img
                    src={onlinestore5WD}
                    alt=""
                    className="imgResultsProduct2"
                />
              </Col>
            </Row>
            <Row className="wrapRowOnlineStore10">
              <Col xl="6" lg="6" md="12" sm="12">
                <p className="pOnlineStore1">
                  Remarkably, the solution achieved a return on investment, covering both development and implementation costs within a mere 6 months.
                  <br />
                  <br />
                  The forthcoming phases of the project include further development and the creation of new online products based on this platform, illustrating ongoing innovation and expansion.
                </p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img
                    src={onlinestore6WD}
                    alt=""
                    className="imgResultsProduct3"
                />
              </Col>
            </Row>
            <Row className="wrapRowDevelopmentProcessText">
              <Col xl="12" lg="12" md="12" sm="12">
                <h2 className="h2DevelopmentProcess">Conclusion</h2>
              </Col>
            </Row>
            <Row className="wrapRowConclusion">
              <Col xl="6" lg="6" md="12" sm="12">
                <div className="wrapConclusionText">
                  <p className="pConclusion">
                    This case study serves as a compelling example of how, even during challenging periods like quarantine, the launch of new solutions can effectively assist insurance companies in reaching and attracting new customers. Notably, this solution has the added benefit of enabling individuals without prior experience in insurance to generate additional income. It underscores the value of innovation and adaptability in transforming customer engagement and business operations.
                  </p>
                  <a href="/en/contact" className="btn buttonOne">Contact us</a>
                </div>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img src={imgConclusion} alt="" className="imgConclusion" />
              </Col>
            </Row>
          </div>
        </Container>
      </div>

  );
}

export default OnlineStore;
