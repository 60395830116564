import React from "react";
import "./../css/careers.scss";
import { Container, Row, Col } from "react-bootstrap";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <Container>
        <div className="wrapBG">
          <Row className="wrapRowPrivacyPolicy">
            <Col xl="12" lg="12" md="12" sm="12">
              <h1 className="h1PrivacyPolicy">PRIVACY NOTICE</h1>
              <div className="wrapPrivacyPolicyBlock">
                <p className="pPrivacyPolicy">
                  This privacy notice sets out how Sagax Software uses and
                  protects any information that you give Sagax Software when you
                  use this website. Please note that the information will be
                  processes outside the EU/EEA to fulfill the purpose for
                  collection and processing the Personal Data.
                </p>
                <p className="pPrivacyPolicy">
                  Sagax Software is committed to ensuring that your privacy is
                  appropriately protected. Should we ask you to provide certain
                  information by which you can be identified when using this
                  website, then you can be assured that it will only be used in
                  accordance with this privacy statement.
                </p>
                <p className="pPrivacyPolicy">
                  Sagax Software may change this notice from time to time by
                  updating this page. You should check this page from time to
                  time to ensure that you are happy with any changes. This
                  notice is effective from 01.08.2023.
                </p>
                <h2 className="h2PrivacyPolicy">What we collect</h2>
                <p className="pPrivacyPolicy">
                  We may collect the following information:
                </p>
                <ul>
                  <li className="liPrivacyPolicy">Name and job title;</li>
                  <li className="liPrivacyPolicy">
                    Contact information including email address;
                  </li>
                  <li className="liPrivacyPolicy">
                    Demographic information such as postcode, preferences and
                    interests;
                  </li>
                  <li className="liPrivacyPolicy">
                    Other information relevant to customer requests and/or
                    offers.
                  </li>
                </ul>
                <h2 className="h2PrivacyPolicy">
                  What we do with the information we gather
                </h2>
                <p className="pPrivacyPolicy">
                  The information provided may be added to our CRM and other
                  internal systems for further processing. The information will
                  be stored in our internal systems including CRM until you
                  request it to be deleted. We require this information to
                  understand your needs and provide you with a better service,
                  and in particular for the following reasons:
                </p>
                <ul>
                  <li className="liPrivacyPolicy">Internal record keeping;</li>
                  <li className="liPrivacyPolicy">
                    We may use the information to improve our products and
                    services;
                  </li>
                  <li className="liPrivacyPolicy">
                    We may periodically send promotional emails about new
                    projects, special offers or other information which we think
                    you may find interesting using the email address which you
                    have provided;
                  </li>
                  <li className="liPrivacyPolicy">
                    From time to time, we may also use your information to
                    contact you for market research purposes. We may contact you
                    by email, phone, fax or mail. We may use the information to
                    customize the website according to your interests.
                  </li>
                </ul>
                <h2 className="h2PrivacyPolicy">Security</h2>
                <p className="pPrivacyPolicy">
                  We are committed to ensuring that your information is secure.
                  In order to prevent unauthorized access or disclosure, we have
                  put in place suitable physical, electronic and managerial
                  controls to safeguard and secure the information we collect
                  online.
                </p>
                <h2 className="h2PrivacyPolicy">How we use cookies</h2>
                <p className="pPrivacyPolicy">
                  A cookie is a small file which asks permission to be placed on
                  your computer's hard drive. Once you agree, the file is added
                  and the cookie helps analyze web traffic or lets you know when
                  you visit a particular site. Cookies allow web applications to
                  respond to you as an individual. The web application can
                  tailor its operations to your needs, likes and dislikes by
                  gathering and remembering information about your preferences.
                </p>
                <p className="pPrivacyPolicy">
                  We use traffic log cookies to identify which pages are being
                  used. This helps us analyze data about web page traffic and
                  improve our website in order to tailor it to customer needs.
                  We only use this information for statistical analysis purposes
                  and then the data is removed from the system.
                </p>
                <p className="pPrivacyPolicy">
                  Overall, cookies help us provide you with a better website, by
                  enabling us to monitor which pages you find useful and which
                  you do not. A cookie in no way gives us access to your
                  computer or any information about you, other than the data you
                  choose to share with us.
                </p>
                <p className="pPrivacyPolicy">
                  You can choose to accept or decline cookies. Most web browsers
                  automatically accept cookies, but you can usually modify your
                  browser setting to decline cookies if you prefer. This may
                  prevent you from taking full advantage of the website.
                </p>
                <h2 className="h2PrivacyPolicy">Links to other websites</h2>
                <p className="pPrivacyPolicy">
                  Our website may contain links to other websites of interest.
                  However, once you have used these links to leave our site, you
                  should note that we do not have any control over that other
                  website. Therefore, we cannot be responsible for the
                  protection and privacy of any information which you provide
                  whilst visiting such sites and such sites are not governed by
                  this privacy statement. You should exercise caution and look
                  at the privacy statement applicable to the website in
                  question.
                </p>
                <h2 className="h2PrivacyPolicy">Children’s privacy</h2>
                <p className="pPrivacyPolicy">
                  Our Services do not address children under the age defined by
                  the regulations of the country of origin (13 years of age or
                  more depending on the country). We do not knowingly collect
                  personal identifiable information from children under age. In
                  case we discover that a child under age has provided us with
                  personal information, we immediately delete this from our
                  servers. If you are a parent or guardian and you are aware
                  that your child has provided us with personal information,
                  please contact us at the e-mail provided in the next section
                  so that we will be able to do necessary actions to delete
                  their personal data.
                </p>
                <h2 className="h2PrivacyPolicy">
                  Сontrolling your personal information
                </h2>
                <p className="pPrivacyPolicy">
                  You may request details of personal information which we hold
                  about you under the General Data Protection Regulation in EU
                  or relevant Ukrainian Law. A small fee might be payable in
                  cases of manifestly unfounded or excessive requests according
                  to the GDPR requirements and according to the Ukrainian
                  legislation in case of access of other persons to personal
                  data. If you would like a copy of the information held on you
                  please write to
                </p>
                <a href="mailto:info@sagax.software" className="aPrivacyPolicy">
                  info@sagax.software
                </a>
                <p className="pPrivacyPolicy">
                  If you believe that any information we are holding on you is
                  incorrect or incomplete, please write to or email us as soon
                  as possible, at the above address. We will promptly correct
                  any information found to be incorrect.
                </p>
                <p className="pPrivacyPolicy">
                  You may choose to restrict the collection or use of your
                  personal information. If you would like to recall your consent
                  for your personal data being processed and stored by us, or
                  restrict the data use for direct marketing purposes, please
                  reach us at the e-mail address below with corresponding
                  request – your data will be removed from storage and
                  processing within a month.
                </p>
                <a href="mailto:info@sagax.software" className="aPrivacyPolicy">
                  info@sagax.software
                </a>
                <p className="pPrivacyPolicy">
                  We will not sell, distribute or lease your personal
                  information to third parties unless we have your permission or
                  are required by law to do so. We may use your personal
                  information to send you promotional information about third
                  parties which we think you may find interesting if you tell us
                  that you wish this to happen.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
export default PrivacyPolicyPage;
