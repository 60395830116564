import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./../../css/products.scss";

import insuranceonlinestore11x from "./../../images/insuranceonlinestore/insurance-online-store-1-1x.png";
import insuranceonlinestore12x from "./../../images/insuranceonlinestore/insurance-online-store-1-2x.png";
import insuranceonlinestore21x from "./../../images/insuranceonlinestore/insurance-online-store-2-1x.png";
import insuranceonlinestore22x from "./../../images/insuranceonlinestore/insurance-online-store-2-2x.png";
import insuranceonlinestore31x from "./../../images/insuranceonlinestore/insurance-online-store-3-1x.png";
import insuranceonlinestore32x from "./../../images/insuranceonlinestore/insurance-online-store-3-2x.png";
import insuranceonlinestore41x from "./../../images/insuranceonlinestore/insurance-online-store-4-1x.png";
import insuranceonlinestore42x from "./../../images/insuranceonlinestore/insurance-online-store-4-2x.png";
import insuranceonlinestore51x from "./../../images/insuranceonlinestore/insurance-online-store-5-1x.png";
import insuranceonlinestore52x from "./../../images/insuranceonlinestore/insurance-online-store-5-2x.png";

import imgPineapple1 from "./../../img/imgPineapple1.svg";

function Insuranceonlinestore(props) {
	
	const [insuranceonlinestore1WD, setInsuranceonlinestore1WD] = useState('');
	const [insuranceonlinestore2WD, setInsuranceonlinestore2WD] = useState('');
	const [insuranceonlinestore3WD, setInsuranceonlinestore3WD] = useState('');
	const [insuranceonlinestore4WD, setInsuranceonlinestore4WD] = useState('');
	const [insuranceonlinestore5WD, setInsuranceonlinestore5WD] = useState('');
	
   useEffect(() => {
	  if( props.wd < 1200 ){
		  setInsuranceonlinestore1WD(insuranceonlinestore11x);
		  setInsuranceonlinestore2WD(insuranceonlinestore21x);
		  setInsuranceonlinestore3WD(insuranceonlinestore31x);
		  setInsuranceonlinestore4WD(insuranceonlinestore41x);
		  setInsuranceonlinestore5WD(insuranceonlinestore51x);
	  } else if( props.wd >= 1200 ){
		  setInsuranceonlinestore1WD(insuranceonlinestore12x);
		  setInsuranceonlinestore2WD(insuranceonlinestore22x);
		  setInsuranceonlinestore3WD(insuranceonlinestore32x);
		  setInsuranceonlinestore4WD(insuranceonlinestore42x);
		  setInsuranceonlinestore5WD(insuranceonlinestore52x);
	  }
   },[props.wd]);
  
  return (
  
	<div>
        <Container>
          <div className="wrapBG">
            <Row className="wrapRowProducts1">
              <Col xl="6" lg="6" md="12" sm="12">
                <h1 className="h1insuranceApiPlatform h2insuranceApiPlatform1">
                  <span>Insurance</span><br/>Online Store
                </h1>
                <p className="pProducts width80">
                  Achieve rapid time-to-market for new online insurance products. Deliver an exceptional online experience to your clients.
                </p>
                <a href="/en/contact" className="btn buttonOne aProduct">
                  Contact us
                </a>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img src={insuranceonlinestore1WD} alt="" className="imgProduvts1" />
              </Col>
            </Row>
            <Row className="wrapRowProducts2">
              <Col xl="6" lg="6" md="12" sm="12" className="imgProduvts2top">
                <img src={insuranceonlinestore2WD} alt="" className="imgProduvts2" />
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <h2 className="h2Products">THE COMPLETE ONLINE SALES PROCESS</h2>
                <p className="pProducts">
                  Empower your clients with the following capabilities:
                  <br />- Online premium calculation.
                  <br />- Easy selection of appropriate coverage and data input.
                  <br />- Online contract signing and payment processing.
                </p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" className="imgProduvts2bottom">
                <img src={insuranceonlinestore2WD} alt="" className="imgProduvts2" />
              </Col>
            </Row>
            <Row className="wrapRowProducts3">
              <Col xl="6" lg="6" md="12" sm="12">
                <h2 className="h2Products">
                  OFFER A FULL RANGE OF INSURANCE PRODUCTS ONLINE
                </h2>
                <p className="pProducts">
                  - Motor Insurance
                  <br />- Travel Insurance
                  <br />- Property Insurance
                </p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img src={insuranceonlinestore3WD} alt="" className="imgProduvts3" />
              </Col>
            </Row>
            <Row className="wrapRowProducts4">
              <Col xl="6" lg="6" md="12" sm="12" className="imgProduvts4top">
                <img src={insuranceonlinestore4WD} alt="" className="imgProduvts4" />
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <h2 className="h2Products">
                  SET UP BUSINESS PROCESSES TO BOOST YOUR ONLINE SALES
                </h2>
                <p className="pProducts">
                  - Provide easy access to essential data through the sales dashboard
                  <br />- Automated lead creation with customer data who haven't finished the purchase
                  <br />- Implement functionality to increase sales
                </p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12" className="imgProduvts4bottom">
                <img src={insuranceonlinestore4WD} alt="" className="imgProduvts4" />
              </Col>
            </Row>
            <Row className="wrapRowProducts5">
              <Col xl="6" lg="6" md="12" sm="12">
                <h2 className="h2Products">Empower yourself with the ability to: </h2>
                <p className="pProducts">
                  - Customize your sales process to fit your unique needs.
                  <br />- Easily manage and fine-tune tariff calculation rules.
                  <br />- Configure business rules and tailor printouts to your specifications.
                </p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <img src={insuranceonlinestore5WD} alt="" className="imgProduvts5" />
              </Col>
            </Row>
            <Row className="wrapRowProducts6">
              <Col xl="6" lg="6" md="12" sm="12">
                <img
                  src={imgPineapple1}
                  alt=""
                  className="imgPineappleProduct"
                />
                <h2 className="h2Products">WANT TO LEARN MORE?</h2>
                <p className="pProducts">
                  Share your business requirements with us, and we'll tailor the ideal solution to meet your needs.
                </p>
              </Col>
              <Col xl="6" lg="6" md="12" sm="12">
                <a href="/en/contact" className="btn buttonOne buttonProduct">Contact us</a>
              </Col>
            </Row>
          </div>
        </Container>
	</div>
  
  );
}

export default Insuranceonlinestore;
