import React from "react";
import axios from "axios";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
// import closeButton from "./../img/closeButton.svg";
import imgFormSubsciberFlover from "./../img/imgFormSubsciberFlover.svg";
import "./../css/Successfully.scss";
import imgSuccessfully from "./../img/imgSuccessfully.svg";
class FormikSubscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFormCV: false,
      showSuccessfully: false,
      inputName: "",
      inputEmail: "",

      inputNameStart: true,
      inputEmailStart: true,

      inputNameLabelClassName: "errorEmptyValue",
      inputEmailLabelClassName: "errorEmptyValue",

      formErrors: {
          inputName: '', 
          inputEmail: '',
      },

      inputNameValid: false,
      inputEmailValid: false,

      formValid: false
    };
    this.hendleOpenForm = this.hendleOpenForm.bind(this);
    this.hendleCloseForm = this.hendleCloseForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.hendleCloseSuccessfully = this.hendleCloseSuccessfully.bind(this);
    this.onClickButton = this.onClickButton.bind(this);
  }

  hendleOpenForm = (e) => {
    this.setState((state) => ({
      showPrivacyPolicy: true,
    }));
  };

  hendleCloseForm = (e) => {
    this.setState((state) => ({
      showPrivacyPolicy: false,
    }));
  };

  
  hendleCloseSuccessfully = (e) => {
    this.setState((state) => ({
      showSuccessfully: false,
    }));
  };
  
  onSubmit = () => {
    this.validateField('inputName', this.state.inputName);
    this.validateField('inputEmail', this.state.inputEmail);

    this.validateForm()
    if(this.state.formValid){
      axios
        .post("/subscribe", {
          name: this.state.inputName,
          email: this.state.inputEmail,
        })
        .then((response) => {
          console.log(response);
        });

      this.setState((state) => ({
        showSuccessfully: true,
      }));
      setTimeout(() => {
        this.setState((state) => ({
          showSuccessfully: false,
          inputName: "",
          inputEmail: "",
        }));
      }, 3000);
    }
  };

  handleUserInput (e) {
    let name = e.target.name;
    let value = e.target.value;
    this.setState((state) => ({
        [name]: value
      }),
      () => { this.validateField(name, value) }
    );
  }

  validateField(fieldName, value) {

    let fieldValidationErrors = this.state.formErrors;

    // let fieldInputNameStart = this.state.inputNameStart;
    // let fieldInputEmailStart = this.state.inputEmailStart;
    
    let inputNameValid = this.state.inputNameValid;
    let inputEmailValid = this.state.inputEmailValid;

    switch(fieldName) {
    case 'inputName':
        inputNameValid = value.length >= 1;
        if(inputNameValid){
          fieldValidationErrors.inputName = '';
          this.setState((state) => ({ inputNameLabelClassName: "errorNotEmptyValue" }) );
        } else {
          fieldValidationErrors.inputName = 'Enter name';
          if(!value.trim()){
            this.setState((state) => ({ inputNameLabelClassName: "errorRed errorEmptyValue errorRedBorder" }) );
          } else {
            this.setState((state) => ({ inputNameLabelClassName: "errorRed errorNotEmptyValue errorRedBorder" }) );
          }
        }
        // fieldInputNameStart = fieldValidationErrors.inputName ? false : true;
        break;
    case 'inputEmail':
        inputEmailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        if(inputEmailValid){
          fieldValidationErrors.inputEmail = '';
          this.setState((state) => ({ inputEmailLabelClassName: "errorNotEmptyValue" }) );
        } else {
          fieldValidationErrors.inputEmail = 'This email is not correct!';
          if(!value.trim()){
            this.setState((state) => ({ inputEmailLabelClassName: "errorRed errorEmptyValue errorRedBorder" }) );
          } else {
            this.setState((state) => ({ inputEmailLabelClassName: "errorRed errorNotEmptyValue errorRedBorder" }) );
          }
        }
        // fieldInputEmailStart = fieldValidationErrors.inputEmail ? false : true;
        break;
    default:
        break;
    }

    this.setState(
      {
          formErrors: fieldValidationErrors,

          inputNameValid: inputNameValid,
          inputEmailValid: inputEmailValid,
      }, this.validateForm);
  }

  validateForm() {
      this.setState(
          {
              formValid: this.state.inputNameValid &&
                              this.state.inputEmailValid
          }
      );
  }
  
  onClickButton (event) {
    event.preventDefault();
    this.onSubmit();
  }

  render() {
    return (
      <div>
        {/* Successfully */}
        {this.state.showSuccessfully ? (
          <Container>
            <Row>
              <Col>
                <div
                  className="wrapSuccessfullyBG"
                  onClick={this.hendleCloseSuccessfully}
                >
                  <div
                    className="wrapSuccessfully"
                    onClick={this.hendleCloseSuccessfully}
                  >
                    <h2
                      className="h2Successfully"
                      onClick={this.hendleCloseSuccessfully}
                    >
                      successfully
                    </h2>
                    <p
                      className="pSuccessfully"
                      onClick={this.hendleCloseSuccessfully}
                    >
                      Your message has been sent successfully
                    </p>
                    <img
                      src={imgSuccessfully}
                      alt=""
                      className="imgSuccessfully"
                      onClick={this.hendleCloseSuccessfully}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : null}
        {/* Successfully */}

        <div className="wrapFormSubscriber">
          <Container>
            <Row>
              <Col>
                <h2 className="h2Form">Sign up for Our Blog</h2>
                
                  
                <Form className="formSendCV formSubscriber" id="formlead" onSubmit={(e) => {this.onSubmit(); e.preventDefault();}}>
                    {/* start inputName */}
                    <Row className="wrapFormRow">
                      <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                        <div className="form-group">
                          <input
                            className={this.state.formErrors.inputName ? "form-control errorRedBorder" : "form-control"} 
                            type="text"
                            name="inputName"
                            placeholder=""
                            value={this.state.inputName}
                            onChange={this.handleUserInput}
                          />
                          <label 
                            className={this.state.inputNameLabelClassName}
                            htmlFor="exampleInputEmail1">
                              Name*
                          </label>
                          <div className="error errorInput">{this.state.formErrors.inputName}</div>
                        </div>
                      </Col>
                    </Row>
                    {/* end inputName */}
                    {/* start inputEmail */}
                    <Row className="wrapFormRow">
                      <Col xl="12" lg="12" md="12" sm="12" className="wrapFormCol">
                        <div className="form-group">
                          <input
                            className={this.state.formErrors.inputEmail ? "form-control errorRedBorder" : "form-control"} 
                            type="email"
                            name="inputEmail"
                            placeholder=""
                            value={this.state.inputEmail}
                            onChange={this.handleUserInput}
                          />
                          <label 
                            className={this.state.inputEmailLabelClassName}
                            htmlFor="exampleInputEmail1">
                            Email*
                          </label>
                          <div className="error errorInput">{this.state.formErrors.inputEmail}</div>
                        </div>
                      </Col>
                    </Row>
                    {/* end inputEmail */}
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn buttonOne buttonOneForm"
                      onClick={this.onClickButton}
                    >
                      Send
                    </Button>
                  </Form>


                <img
                  src={imgFormSubsciberFlover}
                  alt=""
                  className="imgFormSubsciberFlover"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
export default FormikSubscribe;
